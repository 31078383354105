import React, { FC, Fragment, useEffect, useState } from "react";
import "./journeyTemplateCarousels.scss";
//@ts-ignore
import ItemsCarousel from "react-items-carousel";
import carouselPlaceholder from "../../../../../assets/surgery.png";
import JourneyTemplateCarouselBig from "./JourneyTemplateCarouselBig";
import JourneyTemplateAttachmentService from "../../../../../services/JourneyTemplateService/journeyTemplateAttachment.service";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import JourneyStepAttachmentService from "../../../../../services/JourneyService/journeyStepAttachment.service";
import { JourneyTemplateAttachment } from "../../../../../models/JourneyTemplate/journeyTemplateAttachment.model";
import { JourneyTemplateStep } from "../../../../../models/JourneyTemplate/journeyTemplateStep.model";

interface JourneyTemplateCarouselsProps {
  step: JourneyTemplateStep;
  fromJourney?: boolean;
}

const JourneyTemplateCarousels: FC<JourneyTemplateCarouselsProps> = (props) => {
  const { step, fromJourney } = props;

  const { journeyTemplateAttachmentList, fetchJourneyTemplateAttachments } =
    JourneyTemplateAttachmentService();
  const { journeyStepAttachmentList, fetchJourneyStepAttachments } =
    JourneyStepAttachmentService();

  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [carouselItems, setCarouselItems] = useState<
    Array<JourneyTemplateAttachment>
  >([]);
  const chevronWidth = 40;

  useEffect(() => {
    setCarouselItems([]);
    if (step?.id)
      fromJourney
        ? fetchJourneyStepAttachments(step?.id, "carousel")
        : fetchJourneyTemplateAttachments(step?.id, "carousel");
  }, [step]);

  useEffect(() => {
    setCarouselItems(
      fromJourney ? journeyStepAttachmentList : journeyTemplateAttachmentList
    );
  }, [journeyStepAttachmentList, journeyTemplateAttachmentList]);

  return (
    <Fragment>
      {!!carouselItems.length && <div className="journey-template-step__label">Carousel</div>}
      <div className="journey-template-carousels">
        {carouselItems.length > 0 && (
          <>
            {visible && (
              <JourneyTemplateCarouselBig
                visible={visible}
                setVisible={setVisible}
                imageList={carouselItems}
              />
            )}
            <ButtonComponent
              text={"Show fullscreen"}
              onClick={() => setVisible(true)}
              type={"text"}
              className={"journey-template-carousels__fullscreen"}
            />
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={4}
              gutter={20}
              leftChevron={
                <div className="journey-template-carousels__navigator left" />
              }
              rightChevron={
                <div className="journey-template-carousels__navigator right" />
              }
              outsideChevron
              chevronWidth={chevronWidth}
            >
              {carouselItems?.map((attachment, i) => (
                <div
                  className="journey-template-carousels__image"
                  key={i}
                  onClick={() => {
                    setActiveItemIndex(i);
                  }}
                >
                  <img
                    src={attachment?.attachmentUrl}
                    alt=""
                    className="journey-template-carousels__image-thumbnail"
                  />
                </div>
              ))}
            </ItemsCarousel>
            <div className="journey-template-carousels__description">
              {carouselItems[activeItemIndex]?.description ?? ""}
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default JourneyTemplateCarousels;
