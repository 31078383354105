import * as Yup from  "yup";

export const articleValidationSchema = Yup.object().shape({
    title: Yup.string()
        .required('Title is required!'),
    authorName: Yup.string()
        .required('Author name is required!'),
    content: Yup.string()
        .required('Content is required!'),
    tagIds: Yup.array(),
    priorityId: Yup.string()
        .required('Priority is required!'),
})
