import { serializable, alias, primitive } from 'serializr';
import { CheckListStatus } from '../../enums/checkListStatus.enum';

export class JourneyTemplateChecklist {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('id', primitive()))
    key?: number;

    @serializable(alias('content', primitive()))
    content?: string;

    @serializable(alias('order', primitive()))
    order?: number;

    @serializable(alias('template_step_id', primitive()))
    journeyTemplateStepId?: number;

    @serializable(alias('status', primitive()))
    status?: CheckListStatus;

}
