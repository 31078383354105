import React, { FC, useEffect, useState } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import "./journeyTemplateChecklistForm.scss";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import { Input, Switch } from "antd";
import JourneyTemplateChecklistService from "../../../../../../services/JourneyTemplateService/journeyTemplateChecklist.service";
import DeleteConfirmation from "../../../../../../shared/components/DeleteConfirmation";
import { JourneyTemplateChecklist } from "../../../../../../models/JourneyTemplate/journeyTemplateChecklist.model";
import JourneyChecklistService from "../../../../../../services/JourneyService/journeyStepChecklist.service";
import { ReactSortable } from "react-sortablejs";
import JourneyTemplateChecklistItem from "../JourneyTemplateChecklistItem";
import SkeletonLoader from "../../../../../../shared/components/SkeletonLoader";
import { JourneyTemplateStep } from "../../../../../../models/JourneyTemplate/journeyTemplateStep.model";

interface JourneyTemplateChecklistFormProps {
  step?: JourneyTemplateStep;
  showChecklist: boolean;
  setShowChecklist: Function;
  customizeJourney?: boolean;
  setFieldValue: Function;
}

const JourneyTemplateChecklistForm: FC<JourneyTemplateChecklistFormProps> = ({
  step,
  showChecklist,
  setShowChecklist,
  customizeJourney,
  setFieldValue,
}) => {
  const [noOfItems, setNoOfItems] = useState<number>(0);

  const [showDelete, setShowDelete] = useState<boolean>(false);

  const [currentItem, setCurrentItem] = useState<JourneyTemplateChecklist>(
    new JourneyTemplateChecklist()
  );

  const [checklistItems, setChecklistItems] = useState<
    Array<JourneyTemplateChecklist>
  >([]);

  const [checklistOrderedList, setChecklistOrderedList] = useState<any[]>([]);

  const [requireValidation, setRequireValidation] = useState(false);

  const {
    journeyTemplateChecklistItems,
    fetchJourneyTemplateChecklists,
    updateJourneyTemplateChecklist,
    updateJourneyTemplateChecklistOrder,
    createJourneyTemplateChecklist,
    deleteJourneyTemplateChecklist,
    buttonLoading,
  } = JourneyTemplateChecklistService();

  const {
    journeyChecklistItems,
    checklistLoading,
    fetchJourneyChecklists,
    updateJourneyChecklistOrder,
    updateJourneyChecklist,
    createJourneyChecklist,
    deleteJourneyChecklist,
    checklistButtonLoading,
  } = JourneyChecklistService();

  useEffect(() => {
    if (step?.id)
      !customizeJourney
        ? fetchJourneyTemplateChecklists(step?.id)
        : fetchJourneyChecklists(step?.id);
    else setShowChecklist(false);
  }, [step]);

  useEffect(() => {
    setFieldValue("requireValidation", requireValidation)
  }, [requireValidation])

  useEffect(() => {
    setRequireValidation(!!step?.requireValidation)
  }, [step?.requireValidation])

  useEffect(() => {
    setShowChecklist(
      customizeJourney
        ? journeyChecklistItems.length > 0
        : journeyTemplateChecklistItems.length > 0
    );
    setChecklistItems(
      customizeJourney ? journeyChecklistItems : journeyTemplateChecklistItems
    );
  }, [customizeJourney, journeyChecklistItems, journeyTemplateChecklistItems]);

  const handleAddItem = () => {
    setNoOfItems(1);
  };

  const handleDeleteItem = (x: JourneyTemplateChecklist) => {
    if (x?.id) {
      setCurrentItem(x);
      setShowDelete(true);
    } else setNoOfItems(noOfItems - 1);
  };

  const handleChecklistItemBlur = (
    item: JourneyTemplateStep,
    event: any,
    index: number
  ) => {
    const data = {
      content: event?.target?.value,
      order: index + 1,
    };
    if (item?.id) {
      customizeJourney
        ? updateJourneyChecklist(step?.id, item?.id, data).then(() => {
          fetchJourneyChecklists(step?.id)
          setNoOfItems(0)
        }
        )
        : updateJourneyTemplateChecklist(step?.id, item?.id, data).then(() => {
          fetchJourneyTemplateChecklists(step?.id)
          setNoOfItems(0)
        }
        );
    } else {
      customizeJourney
        ? createJourneyChecklist(data, step?.id).then(() => {
          fetchJourneyChecklists(step?.id)
          setNoOfItems(0)
        }
        )
        : createJourneyTemplateChecklist(data, step?.id).then(() => {
          fetchJourneyTemplateChecklists(step?.id)
          setNoOfItems(0)
        }
        );
    }
  };

  const handleReorder = (e: any) => {
    const { order } = checklistItems[e.newIndex];
    const data = {
      order: order ?? 1,
    };
    if (customizeJourney) {
      updateJourneyChecklistOrder(step?.id, e.item.dataset.id, data).then(() =>
        fetchJourneyChecklists(step?.id)
      );
    } else {
      updateJourneyTemplateChecklistOrder(
        step?.id,
        e.item.dataset.id,
        data
      ).then(() => fetchJourneyTemplateChecklists(step?.id));
    }
  };

  return (
    <div className="journey-template-checklist-form">
      {showChecklist && (
        <>
          <DeleteConfirmation
            showConfirmation={showDelete}
            setShowConfirmation={setShowDelete}
            entityName={"checklist item"}
            handleDelete={() =>
              customizeJourney
                ? deleteJourneyChecklist(step?.id, currentItem?.id)
                : deleteJourneyTemplateChecklist(step?.id, currentItem?.id)
            }
            buttonLoading={buttonLoading}
            refresh={() =>
              customizeJourney
                ? fetchJourneyChecklists(step?.id).then(() => setNoOfItems(0))
                : fetchJourneyTemplateChecklists(step?.id).then(() =>
                  setNoOfItems(0)
                )
            }
          />
          <div className="journey-template-step__checklist-title">
            <div className="journey-template-step__label">Checklist</div>
            <span className="journey-template-step__checklist-switch">
              <span>Requires validation</span>
              <Switch 
                checked={requireValidation}
                onChange={setRequireValidation} />
            </span>
          </div>
          <ReactSortable
            list={checklistItems as any[]}
            setList={setChecklistOrderedList as any}
            onSort={handleReorder}
          >
            {!checklistLoading &&
              checklistItems.map((x, i) => (
                <div className="journey-template-checklist-item" key={x?.id}>
                  <JourneyTemplateChecklistItem
                    key={x?.id}
                    handleChecklistItemBlur={handleChecklistItemBlur}
                    handleDeleteItem={handleDeleteItem}
                    x={x}
                    i={i}
                    customizeJourney={customizeJourney}
                    checklistItems={checklistItems}
                  />
                </div>
              ))}
          </ReactSortable>
          {[...Array(noOfItems)].map((x, i) => {
            return (
              <JourneyTemplateChecklistItem
                handleChecklistItemBlur={handleChecklistItemBlur}
                handleDeleteItem={handleDeleteItem}
                x={x}
                i={i}
                customizeJourney={customizeJourney}
                checklistItems={checklistItems}
              />
            );
          })}
          {checklistLoading &&
            [...Array(4)].map((x) => (
              <div className={"chat__list__head"}>
                <SkeletonLoader avatar={false} rows={1} />
              </div>
            ))}
          <ButtonComponent
            onClick={handleAddItem}
            text={"Add checklist item"}
            type={"text"}
            className={"journey-template-checklist-form__add"}
          />
        </>
      )}
    </div>
  );
};

export default JourneyTemplateChecklistForm;
