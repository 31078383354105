import { Form, Formik, FormikValues } from "formik";
import React from "react";
import { JourneyStatusEnum } from "../../../../../enums/journeyStatus.enum";
import { Journey } from "../../../../../models/Journey/journey.model";
import JourneyService from "../../../../../services/JourneyService/journey.service";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import DropdownField from "../../../../../shared/components/DropdownField";
import {
  enumArrayToDropdownOptions,
  getJourneyStatusDropdownOptions,
} from "../../../../../shared/utils/constructDropdownOptions";
import "./journeyStatusForm.scss";

interface JourneyStatusFormProps {
  journey?: Journey;
  onSuccess: (response?: boolean) => void;
}

function JourneyStatusForm({ journey, onSuccess }: JourneyStatusFormProps) {
  const { updateJourney } = JourneyService();

  const handleSubmit = ({ status }: FormikValues) => {
    if (journey?.id) {
      updateJourney(
        Object.assign(new Journey(), {
          ...journey,
          status,
        }),
        journey.id.toString()
      ).then(() => {
        onSuccess(true);
      });
    }
  };

  const statusOptions = getJourneyStatusDropdownOptions(journey?.status);

  return (
    <div className="journey-status-form">
      <Formik
        initialValues={{
          status: journey?.status,
        }}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isValid, values, resetForm }) => (
          <Form className="journey-status-form__form">
            <div className="journey-status-form__title">Change Status</div>
            <DropdownField
              className="journey-status-form__status-select"
              name="status"
              options={statusOptions}
              placeHolder="Select status"
              showArrow={true}
              defaultValue={journey?.status}
              value={values?.status}
              onChange={(value) => {
                setFieldValue("status", value);
              }}
            />
            <div className="journey-status-form__form-actions">
              <ButtonComponent
                loading={false}
                type="default"
                text="Cancel"
                onClick={() => onSuccess(false)}
              />
              <ButtonComponent
                htmlType="submit"
                loading={false}
                type="primary"
                text="Confirm"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default JourneyStatusForm;
