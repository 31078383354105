import { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router-dom";
import { JourneyTemplateStep } from "../../models/JourneyTemplate/journeyTemplateStep.model";

const JourneyTemplateStepService = () => {
  const [journeyTemplateStep, setJourneyTemplateStep] =
    useState<JourneyTemplateStep>(new JourneyTemplateStep());

  const [journeyTemplateStepList, setJourneyTemplateStepList] = useState<
    Array<JourneyTemplateStep>
  >([]);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const fetchJourneyTemplateSteps = (id?: number) => {
    let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_STEPS, { id: id });
    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const journeyTemplateSteps: any = deserialize(
          JourneyTemplateStep,
          response.data
        );
        setJourneyTemplateStepList(journeyTemplateSteps);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const createJourneyTemplateStep = (
    id: number,
    data: JourneyTemplateStep,
    onSuccess?: (step: JourneyTemplateStep) => void
  ) => {
    setButtonLoading(true);
    const serializedData = serialize(JourneyTemplateStep, data);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_STEPS, { id: id });
    return axiosInstance
      .post(apiUrl, serializedData)
      .then((response) => {
        const journeyTemplateStep = deserialize(
          JourneyTemplateStep,
          response.data
        );
        setJourneyTemplateStepList([
          ...journeyTemplateStepList,
          journeyTemplateStep,
        ]);
        setJourneyTemplateStep(journeyTemplateStep);
        Notification({
          message: "Success",
          description: "Journey template step has been created successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess && onSuccess(journeyTemplateStep);
      })
      .catch((error) => { })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const updateJourneyTemplateStep = (
    templateCategoryId: number,
    templateStepId?: number,
    data?: JourneyTemplateStep
  ) => {
    setButtonLoading(true);
    const serializedData = serialize(JourneyTemplateStep, data);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_STEPS, {
      id: templateCategoryId,
    });
    return axiosInstance
      .put(apiUrl + `/${templateStepId}`, serializedData)
      .then((response) => {
        const journeyTemplateStep = deserialize(
          JourneyTemplateStep,
          response.data
        );
        setJourneyTemplateStep(journeyTemplateStep);
        Notification({
          message: "Success",
          description: "Journey template step has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
        return journeyTemplateStep
      })
      .catch((error) => { })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const updateJourneyTemplateStepOrder = (
    templateCategoryId: number,
    templateStepId?: number,
    data?: JourneyTemplateStep
  ) => {
    setButtonLoading(true);
    const serializedData = serialize(JourneyTemplateStep, data);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_STEPS_ORDER, {
      id: templateCategoryId,
      stepId: templateStepId,
    });
    return axiosInstance
      .put(apiUrl, serializedData)
      .then((response) => {
        const journeyTemplateStep = deserialize(
          JourneyTemplateStep,
          response.data
        );
        setJourneyTemplateStep(journeyTemplateStep);
        Notification({
          message: "Success",
          description:
            "Journey template step order has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const deleteJourneyTemplateStep = (
    templateId?: number,
    templateStepId?: number
  ) => {
    setButtonLoading(true);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_STEPS, {
      id: templateId,
    });
    return axiosInstance
      .delete(apiUrl + `/${templateStepId}`)
      .then((response) => {
        Notification({
          message: "Success",
          description: "Journey template step has been deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return {
    journeyTemplateStep,
    journeyTemplateStepList,
    buttonLoading,
    fetchJourneyTemplateSteps,
    createJourneyTemplateStep,
    deleteJourneyTemplateStep,
    updateJourneyTemplateStep,
    updateJourneyTemplateStepOrder,
  };
};

export default JourneyTemplateStepService;
