import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "./ResetPasswordValidation";
import UserService from "../../../services/AuthService/auth.service";
import { withRouter } from "react-router-dom";
import "../auth.scss";
import { useHistory, useLocation } from "react-router";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { LOGIN } from "../../../routes/routeConstants/appRoutes";

const initialValue = {
  password: "",
  confirmPassword: "",
};

const ResetPasswordForm = () => {
  const { loading, resetPassword } = UserService();

  const location = useLocation();

  const onSubmit = (values: { password: string; confirmPassword: string }) => {
    const search = location.search;
    const email = new URLSearchParams(search).get("email");
    const resetToken = new URLSearchParams(search).get("password_reset_token");
    const data = {
      email: email,
      password: values?.password,
      password_reset_token: resetToken ?? "1111111111",
    };
    resetPassword(data);
  };

  return (
    <div>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <div className="auth-form__header">Reset Password</div>
          <div className="auth-form__field">
            <div className="form-label">New password</div>
            <InputField
              type="password"
              name="password"
              placeholder="Enter your new password"
            />
          </div>
          <div className="auth-form__field">
            <div className="form-label">Confirm new password</div>
            <InputField
              type="password"
              name="confirmPassword"
              placeholder="Enter your password again"
            />
          </div>
          <ButtonComponent
            htmlType="submit"
            loading={loading}
            className="auth-form__action"
            type="primary"
            text="Change password"
          />
        </Form>
      </Formik>
    </div>
  );
};

export default withRouter(ResetPasswordForm);
