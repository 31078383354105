import {
  serializable,
  alias,
  primitive,
  list,
  object,
  custom,
} from "serializr";

export class TagModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("class_name", primitive()))
  className?: string;
}
