import React, { FC, useEffect, useState } from "react";
import "./tagForm.scss";
import { Form, Formik } from "formik";
import { tagValidationSchema } from "./tagFormValidation";
import InputField from "../../../shared/components/InputField";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { TagModel } from "../../../models/Tag/tag.model";
import TagService from "../../../services/TagService/tags.service";
interface TagFormProps {
  showForm: boolean;
  setShowForm: (showForm: boolean) => void;
  tag?: TagModel;
  refreshList?: Function;
  onSuccess?: Function;
  afterCloseHandler: () => void;
}

const TagForm: FC<TagFormProps> = (props) => {
  const { showForm, setShowForm, tag, refreshList, onSuccess, afterCloseHandler } =
    props;

  const history = useHistory();

  const [initialValue, setInitialValue] = useState<TagModel>(new TagModel());

  const {createTag, updateTag, tagButtonLoading} = TagService()
  useEffect(() => {
    setInitialValue({
      id: tag?.id,
      name: tag?.name,
    });
  }, [tag]);

  const onSubmit = async (
    data: TagModel,
    formProps: { resetForm: () => void }
  ) => {
    const values = { ...data };
    const response = values.id
      ? await updateTag(values,values.id)
      : await createTag(values);
    if (response) {
      formProps.resetForm();
      setShowForm(false);
      refreshList && refreshList();
    } else return;
  };

  return (
    <Modal
      title={null}
      visible={showForm}
      onCancel={() => {
        setShowForm(false);
      }}
      footer={null}
      destroyOnClose
      afterClose={afterCloseHandler}
      maskClosable = {false}
      className="tag-form caretaker-form"
    >
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={tagValidationSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          resetForm,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <div className="tag-form__header text-bold">
              {tag?.id ? "Edit" : "New"} Tag
            </div>

            <div className="tag-form__row">
              <div className="tag-form__field">
                <InputField
                  type="text"
                  name="name"
                  placeholder="Enter tag title"
                />
              </div>
            </div>


            <div className="tag-form__actions">
              <ButtonComponent
                className="tag-form__action"
                type="text"
                text="Cancel"
                onClick={() => {
                  resetForm()
                  setShowForm(false);
                }}
              />
              <ButtonComponent
                htmlType="submit"
                loading={tagButtonLoading}
                className="tag-form__action"
                type="primary"
                text={tag?.id ? "Update" : "Create"}
                disabled={!isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default TagForm;
