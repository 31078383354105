import { Col, Modal, Row } from "antd";
import React, { useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { useJourneyContext } from "../../../context/JourneyContext";
import { JourneyTemplateCategory } from "../../../models/JourneyTemplate/journeyTemplateCategory.model";
import { JourneyTemplateStep } from "../../../models/JourneyTemplate/journeyTemplateStep.model";
import JourneyStepService from "../../../services/JourneyService/journeyStep.service";
import JourneyTemplateStepService from "../../../services/JourneyTemplateService/journeyTemplateStep.service";
import "./journeyCategorySteps.scss";

interface JourneyCategoryStepsProps {
  journeyCategory: JourneyTemplateCategory;
  steps?: JourneyTemplateStep[];
  customizeJourney?: boolean;
  editMode?: boolean;
  onRefresh: () => void;
}

function JourneyCategorySteps({
  journeyCategory,
  steps,
  customizeJourney,
  onRefresh,
  editMode,
}: JourneyCategoryStepsProps) {
  const [stepsOrdered, setSteps] = useState<JourneyTemplateStep[]>([]);

  var swappedStepId: string;

  const {
    journeyDetails: { activeJourneyCategoryStep },
    setActiveJourneyCategoryStep,
    setActiveJourneyCategory,
  } = useJourneyContext();


  const {
    deleteJourneyTemplateStep,
    updateJourneyTemplateStepOrder,
    updateJourneyTemplateStep
  } = JourneyTemplateStepService();

  const {
    deleteJourneyStep,
    updateJourneyStep,
    updateJourneyStepOrder,
  } = JourneyStepService();

  const handleReorderSteps = (e: any) => {
    const stepId = e.item.dataset.id;
    if (steps && steps.length > 0) {
      const stepIndex = steps.findIndex((step) => step.id === Number(stepId));
      if (stepIndex >= 0 && stepId !== swappedStepId) {
        if (e.newIndex < steps.length) {
          const { order } = steps[e.newIndex];
          const data = Object.assign(new JourneyTemplateStep(), {
            order: order ?? 1,
          });
          if (journeyCategory?.id) {
            if (customizeJourney) {
              updateJourneyStepOrder(
                journeyCategory?.id,
                e.item.dataset.id,
                data
              ).then(() => onRefresh());
            } else {
              updateJourneyTemplateStepOrder(
                journeyCategory?.id,
                e.item.dataset.id,
                data
              ).then(() => onRefresh());
            }
          }
        }
      }
    }
  };

  const handleDeleteMilestoneStep = (step: JourneyTemplateStep) => {
    const id = journeyCategory.id
    return Modal.confirm({
      icon: null,
      width: "40%",
      className: "ant-confirm-modal",
      title: "Delete Milestone step",
      content: <div>Do you wish to delete {step.title || ""}?</div>,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        if (customizeJourney) {
          deleteJourneyStep(id, step.id).then(() => {
            onRefresh()
            activeJourneyCategoryStep?.id === step.id &&
              setActiveJourneyCategoryStep(undefined);
          });
        } else {
          deleteJourneyTemplateStep(id, step.id).then(() => {
            onRefresh()
            activeJourneyCategoryStep?.id === step.id &&
              setActiveJourneyCategoryStep(undefined);
          });
        }
      },
      onCancel: () => { },
    });
  };

  const handleSwapCategories = (item: any) => {
    swappedStepId = item.item.dataset.id;
    const categoryId = item.item.dataset.categoryId;
    if (steps && journeyCategory?.id) {
      const data = Object.assign(new JourneyTemplateStep(), {
        journeyCategoryId: customizeJourney ? journeyCategory?.id : undefined,
        journeyTemplateCategoryId: !customizeJourney
          ? journeyCategory?.id
          : undefined,
      });
      customizeJourney
        ? updateJourneyStep(categoryId, Number(swappedStepId), data).then(() =>
          onRefresh()
        )
        : updateJourneyTemplateStep(
          categoryId,
          Number(swappedStepId),
          data
        ).then(() => onRefresh());
    }
  };

  return (
    <ul className="journey-category-steps">
      {steps && steps?.length === 0 && <li>No steps found</li>}
      <ReactSortable
        list={steps as any[]}
        setList={setSteps as any}
        onSort={handleReorderSteps}
        group={{ name: "shared" }}
        onAdd={handleSwapCategories}
      >
        {steps &&
          steps.map((step) => (
            <li
              className={
                activeJourneyCategoryStep?.id === step.id
                  ? "journey-category-steps__active"
                  : ""
              }
              data-category-Id={
                step?.journeyCategoryId ?? step?.journeyTemplateCategoryId
              }
              key={step?.id}
              onClick={() => {
                setActiveJourneyCategory(journeyCategory);
                setActiveJourneyCategoryStep(step);
              }}
            >
              <Row align="middle" wrap={false}>
                <Col flex={1}>
                  {step.title}
                </Col>
                {(editMode || customizeJourney) && <Col onClick={(e) => {
                  e.stopPropagation()
                  step.id && handleDeleteMilestoneStep(step)
                }}>
                  <i className="icon-delete" ></i>
                </Col>}
              </Row>
            </li>
          ))}
      </ReactSortable>
    </ul>
  );
}

export default JourneyCategorySteps;
