import { capitalize } from "lodash";
import { JourneyStatusEnum } from "../../enums/journeyStatus.enum";

export const constructDropdownOptions = (array: any) =>
  array?.map((o: any) => ({
    label: o.name ?? o.fullname ?? o.firstname + " " + o.lastname,
    value: o.id,
  }));

export const enumArrayToDropdownOptions = (array: string[]) =>
  array.map((item) => ({
    label: capitalize(item.split("_").join(" ")),
    value: item,
  }));

export const getJourneyStatusDropdownOptions = (status?: string) => {
  switch (status) {
    case JourneyStatusEnum.DRAFT:
      return enumArrayToDropdownOptions([JourneyStatusEnum.ACTIVE]);
    case JourneyStatusEnum.ACTIVE:
      return enumArrayToDropdownOptions([
        JourneyStatusEnum.DRAFT,
        JourneyStatusEnum.ON_HOLD,
        JourneyStatusEnum.COMPLETED,
      ]);
    case JourneyStatusEnum.ON_HOLD:
      return enumArrayToDropdownOptions([
        JourneyStatusEnum.DRAFT,
        JourneyStatusEnum.ACTIVE,
        JourneyStatusEnum.COMPLETED
      ]);
    case JourneyStatusEnum.COMPLETED:
      return enumArrayToDropdownOptions([
        JourneyStatusEnum.DRAFT,
        JourneyStatusEnum.ACTIVE,
        JourneyStatusEnum.ON_HOLD
      ]);
    default:
      return [];
  }
};
