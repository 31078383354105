import React, { FC, useEffect, useRef, useState } from "react";
import "./faqForm.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory, generatePath } from "react-router";
import { Form, Formik, FormikProps } from "formik";
import InputField from "../../../shared/components/InputField";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { faqValidationSchema } from "./faqFormValidation";
import RichTextField from "../../../shared/components/RichTextField";
import { useLocation, useParams } from "react-router-dom";
import FaqService from "../../../services/FaqService/faq.service";
import { DropdownModeEnum } from "../../../enums/dropdownMode.enum";
import DropdownField from "../../../shared/components/DropdownField";
import MetaService from "../../../services/MetaService/meta.service";
import { FaqModel } from "../../../models/Faq/faq.model";
import SpinnerLoader from "../../../shared/components/SpinnerLoader";
import { MetaModel } from "../../../models/meta.model";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { useIdleTimer } from 'react-idle-timer';
import DeleteConfirmation from "../../../shared/components/DeleteConfirmation";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import GoBack from "../../../shared/components/GoBack";

interface FaqFormProps { }

const FaqForm: FC<FaqFormProps> = (props) => {
  const { } = props;

  const history = useHistory();
  const location = useLocation<any>();
  const search = location?.state?.search || "";

  const { categoryId, id } = useParams<{ categoryId: string, id: string }>();

  const {
    createFaq,
    buttonLoading,
    showFaq,
    faq,
    updateFaq,
    faqLoading,
    fetchCategories,
    categoryList,
    deleteFaq,
  } = FaqService();

  const { tagsMeta, totalTagsItems, currentTagsPage, fetchTags } =
    MetaService();

  const [categoryMeta, setCategoryMeta] = useState<MetaModel[]>([]);

  const [showDelete, setShowDelete] = useState<boolean>(false);

  const formikRef = useRef<FormikProps<FaqModel>>(null)

  const idleTimer = useIdleTimer({
    timeout: 5000,
    onActive: () => idleTimer.reset(),
    onIdle: () => handleAutoSave(),
  })

  const [initialValues, setInitialValues] = useState<any>({
    title: "",
    id: "",
    tagIds: [],
    content: "",
    categoryId,
  });

  useEffect(() => {
    fetchTags();
    fetchCategories();
    if (id !== "new") showFaq(categoryId, id);
  }, [id]);

  useEffect(() => {
    setInitialValues({
      categoryId: faq?.categoryId ?? Number(categoryId),
      id: faq?.id,
      title: faq?.title,
      tagIds: faq?.tags?.map((tag) => tag.label),
      content: faq?.content ?? "",
    });
  }, [faq]);

  const convertTagIdsToLabel = (labels:any) => {
    return labels.map((label:any) => /^\d+$/.test(label) ? tagsMeta.find(tagOption => tagOption?.value?.toString() == label.toString())?.label: label  )
  }
  const handleTagsScroll = (event: any) => {
    let target = event.target;
    if (
      tagsMeta.length < totalTagsItems &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      target.scrollTo(0, target.scrollHeight);
      fetchTags({ page: currentTagsPage + 1 });
    }
  };

  const handleAutoSave = async () => {
    idleTimer.reset()
    if (!formikRef.current?.dirty
      || !formikRef.current?.isValid || !formikRef.current) return;
    const values = Object.assign(new FaqModel(), formikRef.current?.values)

    const response = id === "new"
      ? await createFaq(values, categoryId, formikRef.current?.resetForm, true)
      : await updateFaq(values, categoryId, id, formikRef.current?.resetForm, true);
    if (response) {
      // formikRef.current.()
      setInitialValues({...response, tagIds: response.tags?.map((tag) => tag.label as string)})
      id === "new" && response?.categoryId && response?.id && history.push(
        generatePath(appRoutes.FAQ_FORM, {
          categoryId: response.categoryId,
          id: response.id,
        })
      )
      Notification({
        message: "Autosaved",
        description: "",
        type: NotificationTypes.SUCCESS,
    });
    fetchTags();
    }
  }

  const handleSubmit = async (
    values: any,
    formProps: { resetForm: () => void }
  ) => {

    let response: any;
    if (id === "new")
      response = await createFaq(values, categoryId, formProps.resetForm);
    else
      response = await updateFaq(values, categoryId, id, formProps.resetForm);
    if (response) {
      history.push({pathname: appRoutes.FAQ, hash: response.categoryId +''})
      // if (id === "new") {
      // history.replace(
      //   generatePath(appRoutes.FAQ_FORM, {
      //     categoryId: response.categoryId,
      //     id: response.id,
      //   })
      // );
      // }
    }
  };

  return (
    <div className="app-wrapper faq-form article-details">
     <GoBack showText route={{pathname:appRoutes.FAQ, hash: `${categoryId}`, search:`&search=${search}`}} />
      {faqLoading ? (
        <SpinnerLoader />
      ) : (
        <div className={"faq-form__wrapper"}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
            innerRef={formikRef}
            validationSchema={faqValidationSchema}
          >
            {({ setFieldValue, isValid, values }) => {
              return (
                <Form>
                  <div className="faq-form__field">
                    <div className="label">Category</div>
                    <DropdownField
                      name="categoryId"
                      options={categoryList}
                      placeHolder="Select category"
                      showArrow={true}
                      value={values?.categoryId}
                      onChange={(value) => {
                        setFieldValue("categoryId", value);
                      }}
                      showSearch
                    />
                  </div>
                  <div className="faq-form__field">
                    <div className="label">Title</div>
                    <InputField
                      type="textarea"
                      rows={3}
                      name="title"
                      placeholder="FAQ Title"
                    />
                  </div>
                  <div className="faq-form__field">
                    <RichTextField
                      name={"content"}
                      editorData={values?.content}
                      setFieldValue={setFieldValue}
                      idleTimer = {idleTimer}
                    />
                  </div>
                  <div className="faq-form__field">
                    <div className="label">Tags</div>
                    <DropdownField
                      name="tagIds"
                      options={tagsMeta}
                      placeHolder="Select or type tag names"
                      showArrow={true}
                      value={values?.tagIds}
                      className="statusId__dropdown"
                      onChange={(labels) => {
                        labels = labels.map((label:string) => label.toString().trim()).filter((label:string|number) => label.toString().trim() !== '');
                        setFieldValue("tagIds", convertTagIdsToLabel(labels));
                      }}
                      mode={DropdownModeEnum.TAGS}
                      showSearch
                      onOptionsScroll={(event) => handleTagsScroll(event)}
                    />
                  </div>

                  <div className="faq-form__actions text-right">
                    {formikRef.current?.values?.id
                      ? <ButtonComponent
                        key="faq-cancel"
                        type="text"
                        loading={false}
                        text={"Delete"}
                        onClick={() => setShowDelete(true)}
                      />
                      : <ButtonComponent
                        key="faq-cancel"
                        type="text"
                        loading={false}
                        text={"Cancel"}
                        onClick={() => {
                          history.goBack();
                        }}
                      />}
                    <ButtonComponent
                      key="faq-save"
                      type="primary"
                      htmlType="submit"
                      loading={buttonLoading}
                      text={"Save"}
                      disabled={!isValid}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
          {formikRef.current?.values?.id && <DeleteConfirmation
            handleDelete={() => deleteFaq(categoryId, formikRef.current?.values?.id)}
            setShowConfirmation={setShowDelete}
            showConfirmation={showDelete}
            entityName={"Content"}
            buttonLoading={buttonLoading}
            key="delete-confirmation"
            refresh={() => history.push(appRoutes.FAQ)
            }
          />}
        </div>
      )}
    </div>
  );
};

export default FaqForm;
