import { serializable, alias, primitive, object, list } from "serializr";
import { User } from "../user.model";
import { MetaModel } from "../meta.model";

export class FaqModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("title", primitive()))
  title?: string;

  @serializable(alias("content", primitive()))
  content?: string;

  @serializable(alias("tags", list(object(MetaModel))))
  tags?: MetaModel[] = [];

  @serializable(alias("tag_ids", list(primitive())))
  tagIds?: string[] = [];

  @serializable(alias("created_by_detail", object(User)))
  createdBy?: User;

  @serializable(alias("category_id", primitive()))
  categoryId?: number;

  @serializable(alias("category_name", primitive()))
  categoryName?: string;

  @serializable(alias("order", primitive()))
  order?: number;

  @serializable(alias("class_name", primitive()))
  className?: string;
}
