import {
  serializable,
  alias,
  primitive,
  list,
  object,
  custom,
} from "serializr";
import { MetaModel } from "../meta.model";
import { User } from "../user.model";

export class ArticleModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("id", primitive()))
  key?: number;

  @serializable(alias("title", primitive()))
  title?: string;

  @serializable(alias("author_name", primitive()))
  authorName?: string;

  @serializable(alias("content", primitive()))
  content?: string;

  @serializable(alias("priority", primitive()))
  priorityId?: string = "neutral";

  @serializable(alias("published_at", primitive()))
  publishedAt?: string;

  @serializable(alias("is_published", primitive()))
  isPublished?: boolean;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("tags", list(object(MetaModel))))
  tags?: MetaModel[] = [];

  @serializable(alias("tag_ids", list(primitive())))
  tagIds?: string[];

  @serializable(alias("created_by_detail", object(User)))
  createdBy?: User;

  @serializable(alias("cover_picture_url", primitive()))
  bannerImageUrl?: string;

  bannerImageBase64Url?: string;

  @serializable(
    alias(
      "cover_picture",
      custom(
        (file) => file,
        (json) => {}
      )
    )
  )
  bannerImage?: File;

  @serializable(alias("class_name", primitive()))
  className?: string;
}

export class ArticleCommentModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("content", primitive()))
  content?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("created_by_detail", object(User)))
  createdBy?: User;
}
