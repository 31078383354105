import * as Yup from  "yup";

export const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Enter a valid password!')
        .required('Current password is required!'),
    newPassword: Yup.string()
        .min(8, 'Password has to be longer than 8 characters!')
        .required('Password is required!'),
    confirmPassword: Yup.string()
        .test('passwords-match', 'Passwords must match', function(value){
            return this.parent.newPassword === value
        })
})
