import React, { FC, ReactNode, useEffect } from "react";
import "./journeyTemplateStepDetails.scss";
import JourneyTemplateChecklists from "../JourneyTemplateChecklists";
import JourneyTemplateMembers from "../JourneyTemplateMembers";
import JourneyTemplateCarousels from "../JourneyTemplateCarousels";
import { useHistory } from "react-router";
import JourneyTemplateStepForm from "../JourneyTemplateStepForm";
import { templateStepDropdownOptions } from "../../../../../shared/utils/templateStepDropdownOptions";
import JourneyTemplateAttachments from "../JourneyTemplateAttachments";
import JourneyTemplateAttachmentService from "../../../../../services/JourneyTemplateService/journeyTemplateAttachment.service";
import { JourneyTemplateStep } from "../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import { useJourneyContext } from "../../../../../context/JourneyContext";
import RichTextViewer from "../../../../../shared/components/RichTextViewer";
import { JourneyStepEntityEnum } from '../../../../../enums/journeyStepEntity.enum';
import JourneyTemplateStepEntity from "../JourneyTemplateStepEntity";

interface JourneyTemplateStepDetailsProps {
  refreshSteps?: Function;
  setStep?: Function;
  customizeJourney?: boolean;
}

const JourneyTemplateStepDetails: FC<JourneyTemplateStepDetailsProps> = (
  props
) => {
  const {
    journeyDetails: { activeJourneyCategory, activeJourneyCategoryStep },
  } = useJourneyContext();
  const { customizeJourney } = props;

  const history = useHistory();

  const { journeyTemplateAttachmentList, fetchJourneyTemplateAttachments } =
    JourneyTemplateAttachmentService();

  useEffect(() => {
    if (activeJourneyCategoryStep?.id) {
      fetchJourneyTemplateAttachments(
        activeJourneyCategoryStep?.id,
        "attachment"
      );
    }
  }, [activeJourneyCategoryStep]);

  return activeJourneyCategory?.id ? (
    <div className="journey-template-steps__child">
      <div className="journey-template-step-details">
        {history?.location?.state !== 'edit' && !customizeJourney ? (
          <>
            {activeJourneyCategoryStep && (
              <>
                <div className="journey-template-step__name">
                  {activeJourneyCategoryStep?.title}
                </div>

                <div className="journey-template-step__content">
                  <div className="journey-template-step__label">
                    Description
                  </div>
                  <div className="journey-template-step__value">
                    <RichTextViewer
                      borderless
                      content={activeJourneyCategoryStep?.description}
                    />
                  </div>
                </div>
                <div className="journey-template-step__content">
                  <JourneyTemplateChecklists step={activeJourneyCategoryStep} />
                </div>
                <div className="journey-template-step__content">
                  <JourneyTemplateCarousels step={activeJourneyCategoryStep} />
                </div>
                {activeJourneyCategoryStep.id && (
                  <div className="journey-template-step__content">
                    <JourneyTemplateAttachments
                      disableEmptyState
                      step={activeJourneyCategoryStep}
                      attachments={journeyTemplateAttachmentList}
                      refresh={fetchJourneyTemplateAttachments}
                    />
                  </div>
                )}
                <div className="journey-template-step__content">
                  <JourneyTemplateStepEntity
                    type={JourneyStepEntityEnum.ARTICLE}
                    stepEntities={ activeJourneyCategoryStep?.templateStepArticleList}
                  />

                </div>
                <div className="journey-template-step__content">
                  <JourneyTemplateStepEntity
                    type={JourneyStepEntityEnum.FAQ}
                    stepEntities={ activeJourneyCategoryStep?.templateStepQuestionList}
                  />

                </div>
              </>
            )}
          </>
        ) : (
          <JourneyTemplateStepForm customizeJourney={customizeJourney} />
        )}
      </div>
    </div>
  ) : null;
};

export default JourneyTemplateStepDetails;
