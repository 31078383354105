import React, { FC, useEffect, useState } from "react";
import "./journeyBasicForm.scss";
import { journeyBasicFormValidationSchema } from "./journeyBasicFormValidation";
import { Form, Formik } from "formik";
import InputField from "../../../../shared/components/InputField";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import { Journey } from "../../../../models/Journey/journey.model";
import { useHistory } from "react-router";
import DropdownField from "../../../../shared/components/DropdownField";
import { DropdownModeEnum } from "../../../../enums/dropdownMode.enum";
import DatepickerField from "../../../../shared/components/DatepickerField";
import moment from "moment";
import JourneyService from "../../../../services/JourneyService/journey.service";
import PatientService from "../../../../services/PatientService/patient.service";
import HospitalService from "../../../../services/HospitalService/hospital.service";
import StaffService from "../../../../services/HospitalService/staff.service";
import JourneyTemplateService from "../../../../services/JourneyTemplateService/journeyTemplate.service";
import { constructDropdownOptions } from "../../../../shared/utils/constructDropdownOptions";
import { debounce } from "lodash";
import { Staff } from "../../../../models/Hospital/staff.model";
import CaretakerService from "../../../../services/CaretakerService/caretaker.service";
import MetaService from "../../../../services/MetaService/meta.service";
import { Patient } from "../../../../models/Patient/patient.model";
import { Checkbox } from "antd";
import PhoneNumberInput from "../../../../shared/components/PhoneNumberInput";
import { parsePhoneNumber } from "react-phone-number-input";
import { getFullName } from "../../../../shared/utils/dataFormatConverter";
import { getAgeText } from "../../../../shared/utils/dateHelpers";

interface JourneyBasicFormProps {
  id: string;
  currentStep: number;
  setCurrentStep: Function;
}

const JourneyBasicForm: FC<JourneyBasicFormProps> = (props) => {
  const { id, currentStep, setCurrentStep } = props;

  const history = useHistory();

  const [patientOptions, setPatientOptions] = useState<any[]>([]);

  const [careTakerOptions, setCareTakerOptions] = useState<any[]>([]);

  const { fetchPatients, patientList } = PatientService();

  const { fetchCaretakers, caretakerList } = CaretakerService();

  const { fetchHospitals, hospitalList } = HospitalService();

  const { fetchStaffs, staffList } = StaffService();

  const { fetchJourneyTemplates, journeyTemplateList } =
    JourneyTemplateService();

  const { createJourney, buttonLoading, journey, showJourney, updateJourney } =
    JourneyService();

  const { fetchTags, tagsMeta } = MetaService();

  const [initialValues, setInitialValues] = useState<Journey>(new Journey());

  const [selectedPatient, setSelectedPatient] = useState<Patient>();
  useEffect(() => {
    if (id !== "new") showJourney(id);
    fetchHospitals("", 1, 10, true);
    fetchJourneyTemplates("", 1, 10, true);
    fetchTags();
  }, [id]);
  
  useEffect(() => {
    if (journey?.hospital?.id) {
      // fetchHospitals(journey.hospital.name, 1, 10, true);
      fetchStaffs({ hospital_id: journey?.hospital?.id });
      fetchPatients(journey?.hospital?.id);
      fetchCaretakers(journey?.hospital?.id);
    
    }
    journey?.id &&
      setInitialValues({
        id: journey.id,
        patientId: journey?.patient?.id,
        caretakerId: journey?.caretaker?.id,
        createCaregiver: !journey?.caretaker?.id,
        hospitalId: journey?.hospital?.id,
        name: journey?.name,
        journeyTemplateId: journey?.journeyTemplateId,
        staffIds: journey?.staffs?.filter((staff:Staff) => staff.status).map((staff: Staff) => staff?.id ?? ""),
        description: journey?.description,
        tagIds: (journey?.tags?.map((tag) => tag.value) ||
          []) as unknown as number[],
        surgeryDate: journey?.surgeryDate
          ? moment(journey?.surgeryDate).utc().format("YYYY-MM-DD HH:mm:ss")
          : "",
      });
  }, [journey]);

  const onSubmit = (data: Journey) => {
    const values = { ...data };
    if (values?.phoneNumber) {
      values.isdCode = "+" + parsePhoneNumber(values.phoneNumber)
        ?.countryCallingCode as any;
      values.phoneNumber = parsePhoneNumber(values.phoneNumber)
        ?.nationalNumber as any;
    }
    if (id === "new") createJourney(values);
    else updateJourney(values, id).then(() => setCurrentStep(2));
  };

  useEffect(() => {
    setPatientOptions(
      patientList.map((patient, i) => ({
        label: (
          <div className="journey-basic-form__name-wrapper">
            <div className="journey-basic-form__name">
              <span>
                {patient.fullname ?? patient.firstname + " " + patient.lastname}, 
              </span>
              <span>
                {patient?.dob ? getAgeText(patient?.dob,true) : ""}
              </span>
            </div>
            <div className="journey-basic-form__phone">
              {patient?.isdCode} {patient?.phone}
            </div>
          </div>
        ),
        optionLabel:
          patient.fullname ?? patient.firstname + " " + patient.lastname,
        value: patient.id,
      }))
    );
  }, [patientList]);

  useEffect(() => {
    setCareTakerOptions(
      caretakerList.filter(caretaker => caretaker.isActive).map((caretaker, i) => ({
        label: (
          <div className="journey-basic-form__name-wrapper">
            <div className="journey-basic-form__name">
              <div>
                <span>{getFullName(caretaker)}</span>
              </div>
              <div className="journey-basic-form__email">
                <span>{caretaker?.email ? `${caretaker?.email}` : ""}</span>
              </div>
            </div>
            <div className="journey-basic-form__phone">
              {caretaker?.isdCode} {caretaker?.phone}
            </div>
          </div>
        ),
        optionLabel: getFullName(caretaker),
        value: caretaker.id,
      }))
    );
  }, [caretakerList]);

  return (
    <div className="journey-form">
      <div className="hospital-form journey-basic-form">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={journeyBasicFormValidationSchema}
          enableReinitialize
        >
          {({
            values,
            errors,
            isValid,
            dirty,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form>
              <div className="hospital-form__row">
                <div className="hospital-form__field">
                  <div className="form-label">Hospital</div>
                  <DropdownField
                    name="hospitalId"
                    options={constructDropdownOptions(hospitalList)}
                    placeHolder="Select hospital"
                    showArrow={true}
                    value={values?.hospitalId}
                    className="statusId__dropdown"
                    onChange={(value) => {
                      fetchPatients(value,{metadata: 'metadata'},1,20,true);
                      fetchCaretakers(value,{metadata: 'metadata'},1,20,true);
                      fetchStaffs({ hospital_id: value ,metadata: 'metadata'},1,20,true);
                      setFieldValue("hospitalId", value);
                      setFieldValue("patientId", null);
                      setFieldValue("caretakerId", undefined);
                      setFieldValue("staffIds", undefined);
                    }}
                    showSearch={true}
                  // onSearch={debounce((value) => fetchHospitals(value, 1, 10, true), 1000)}
                  />
                </div>
              </div>
              <div className="hospital-form__row">
                <div className="hospital-form__field">
                  <div className="form-label">Patient</div>
                  <DropdownField
                    name="patientId"
                    options={patientOptions}
                    optionLabelProp="optionLabel"
                    placeHolder="Select patient"
                    showArrow={true}
                    value={values?.patientId}
                    className="statusId__dropdown"
                    onChange={(value) => {
                      setFieldValue("patientId", value);
                      const patient = patientList?.find(
                        (patient) => patient.id === value
                      );
                      setSelectedPatient(patient);
                    }}
                    showSearch={true}
                    onSearch={debounce(
                      (value) =>
                        values?.hospitalId &&
                        fetchPatients(values.hospitalId, { search: value ,metadata:'metadata'},1,10,true)
                    )}
                  />
                  {moment().diff(selectedPatient?.dob, "years") >= 18 &&
                    !values.id && (
                      <div className="mt-3">
                        <Checkbox
                          checked={values.createCaregiver}
                          onChange={(e) => {
                            if (
                              moment().diff(selectedPatient?.dob, "years") < 18
                            )
                              return;

                            setFieldValue("createCaregiver", e.target.checked);

                            if (!e.target.checked)
                              setFieldValue("caretakerId", undefined);
                            else {
                              setFieldValue("email", selectedPatient?.email);
                              setFieldValue(
                                "phoneNumber",
                                (selectedPatient?.isdCode
                                  ? selectedPatient?.isdCode
                                  : "") + selectedPatient?.phone
                              );
                            }
                          }}
                        />
                        <span className="ml-2">Create myself as caregiver</span>
                      </div>
                    )}
                </div>
                {!values.createCaregiver && values.patientId && (
                  <div className="hospital-form__field">
                    <div className="form-label">Caregiver</div>
                    <DropdownField
                      name="caretakerId"
                      optionLabelProp="optionLabel"
                      options={careTakerOptions}
                      placeHolder="Select caregiver"
                      showArrow={true}
                      value={values?.caretakerId}
                      className="statusId__dropdown"
                      onChange={(value) => {
                        setFieldValue("caretakerId", value);
                      }}
                      showSearch={true}
                      onSearch={debounce(
                        (value) =>
                          values?.hospitalId &&
                          fetchCaretakers(values.hospitalId, { search: value, metadata: 'metadata' },1,10,true)
                      )}
                    />
                  </div>
                )}
              </div>
              {!values.id && values.createCaregiver && values.patientId && (
                <div className="hospital-form__row">
                  <div className="hospital-form__field">
                    <div className="form-label">E-mail</div>
                    <InputField
                      type="text"
                      name="email"
                      placeholder="Enter patient's email"
                    />
                  </div>
                  <div className="hospital-form__field">
                    <div className="form-label">Phone number</div>
                    <PhoneNumberInput
                      name={"phoneNumber"}
                      value={values.phoneNumber}
                      // placeholder="Enter patient's phone number"
                      onChange={(value) => {
                        setFieldValue("phoneNumber", value);
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="hospital-form__row">
                <div className="hospital-form__field">
                  <div className="form-label">Journey name</div>
                  <InputField
                    type="text"
                    name="name"
                    placeholder="Enter Journey Name"
                  />
                </div>
                {!journey?.id && (
                  <div className="hospital-form__field">
                    <div className="form-label">Select journey template(Optional)</div>
                    <DropdownField
                      name="journeyTemplateId"
                      options={constructDropdownOptions(journeyTemplateList)}
                      placeHolder="Select journey template"
                      showArrow={true}
                      value={values?.journeyTemplateId}
                      className="statusId__dropdown"
                      onChange={(value) => {
                        setFieldValue("journeyTemplateId", value);
                        const tags =
                          journeyTemplateList.find((temp) => temp.id === value)
                            ?.tags || [];
                        setFieldValue("tagIds", [
                          ...(tags?.map((tag) => tag.value) || []),
                        ]);
                      }}
                      showSearch={true}
                    // onSearch={debounce(
                    //   (value) => fetchJourneyTemplates(value, 1, 10, true),
                    //   1000
                    // )}
                    />
                  </div>
                )}
              </div>

              <div className="hospital-form__row">
                <div className="hospital-form__field">
                  <div className="form-label">Primary date(Optional)</div>
                  <DatepickerField
                    name="surgeryDate"
                    placeholder="Select primary date"
                    value={
                      values?.surgeryDate ? moment(values?.surgeryDate) : null
                    }
                    disableDates="past"
                    onChange={(value, dateString) => {
                      setFieldValue("surgeryDate", dateString);
                    }}
                  />
                </div>
                <div className="hospital-form__field">
                  <div className="form-label">Select clinical team</div>
                  <DropdownField
                    name="staffIds"
                    options={constructDropdownOptions(staffList.filter(staff => staff.status))}
                    placeHolder="Select clinical team"
                    showArrow={true}
                    value={values?.staffIds}
                    className="statusId__dropdown"
                    onChange={(value) => {
                      setFieldValue("staffIds", value);
                    }}
                    mode={DropdownModeEnum.MULTIPLE}
                    showSearch={true}
                    onSearch={debounce(
                      (value) =>
                        fetchStaffs({
                          hospital_id: values?.hospitalId,
                          search: value,
                        },1,20,true),
                      1000
                    )}
                  />
                </div>
              </div>
              <div className="hospital-form__row">
                <div className="hospital-form__field textarea">
                  <div className="form-label">Description(Optional)</div>
                  <InputField
                    type="textarea"
                    name="description"
                    placeholder="Enter description"
                    rows={4}
                  />
                </div>
              </div>
              <div className="hospital-form__row">
                <div className="hospital-form__field tags">
                  <div className="form-label">Tags</div>
                  <DropdownField
                    name="tagIds"
                    options={tagsMeta}
                    placeHolder="Select tags"
                    showArrow={true}
                    mode={DropdownModeEnum.MULTIPLE} 
                    value={values?.tagIds}
                    className="statusId__dropdown"
                    onChange={(value) => {
                      setFieldValue("tagIds", value);
                    }}
                    showSearch={true}
                  />
                </div>
              </div>

              <div className="hospital-form__actions">
                <ButtonComponent
                  className="hospital-form__action"
                  type="text"
                  text="Cancel"
                  onClick={() => history.goBack()}
                />
                <ButtonComponent
                  htmlType="submit"
                  loading={buttonLoading}
                  className="hospital-form__action"
                  type="primary"
                  text={id === "new" ? "Save" : "Update"}
                  disabled={!isValid}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default JourneyBasicForm;
