import React, { useEffect, useState } from "react";
import { JourneyTemplateStep } from "../../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import "./journeyArticles.scss";
import articlePlaceholder from "../../../../../../assets/articlePlaceholder.png";
import userPlaceholder from "../../../../../../assets/userPlaceholder.png";
import { ArticleModel } from "../../../../../../models/Article/article.model";
import JourneyStepService from "../../../../../../services/JourneyService/journeyStep.service";
import moment from "moment";
import RichTextViewer from "../../../../../../shared/components/RichTextViewer";
import { generatePath, useHistory } from "react-router-dom";
import * as appRoutes from "../../../../../../routes/routeConstants/appRoutes";
import EmptyState from "../../../../../../shared/components/EmptyState";
import DpChip from "../../../../../../shared/components/DpChip";
import SpinnerLoader from "../../../../../../shared/components/SpinnerLoader";
interface JourneyArticlesProps {
  step?: JourneyTemplateStep;
}

function JourneyArticles({ step }: JourneyArticlesProps) {
  const history = useHistory();

  const { fetchJourneyArticles, tableLoading, journeyArticles } = JourneyStepService();

  useEffect(() => {
    fetchJourneyArticles(step?.id);
  }, [step]);

  return (
    <div className="journey-articles">
      {tableLoading
        ? <SpinnerLoader />
        : journeyArticles?.length > 0
          ? journeyArticles.map((article) => (
            <div
              className="journey-articles__article-item"
              key={article?.id}
              onClick={() => {
                history.push(
                  generatePath(appRoutes.ARTICLE_VIEW, {
                    id: article?.id,
                  })
                );
              }}
            >
              <div className="journey-articles__article-cover">
                <img
                  src={article?.bannerImageUrl ?? articlePlaceholder}
                  alt="Content Cover"
                />
              </div>
              <div className="journey-articles__article-item-info">
                <div className="journey-articles__article-item-title">
                  {article?.title}
                </div>
                <div className="journey-articles__article-item-author">
                  <span className="journey-articles__article-item-author-info">
                    <label>Posted on: </label>
                    <span className="journey-articles__article-item-author-value">
                      {article?.publishedAt
                        ? moment(article?.publishedAt).format(
                          "DD/MM/YYYY HH:mm a"
                        )
                        : "-"}
                    </span>
                  </span>
                  <span className="journey-articles__article-item-author-info">
                    <label>Published by: </label>
                    {/* <img
                    src={
                      article?.createdBy ?? userPlaceholder
                    }
                    alt="Author image"
                  /> */}
                    <DpChip size="mini" name={article?.authorName ?? ""}
                      url={article.createdBy?.displayPictureUrl}
                    />
                    <span className="journey-articles__article-item-author-value">
                      {article?.authorName ?? "NA"}
                    </span>
                  </span>
                </div>
                <div className="journey-articles__article-item-description">
                  <RichTextViewer height={300} content={article?.content} />
                </div>
              </div>
            </div>
          ))
          : <EmptyState text="No articles available" />}
    </div>
  );
}

export default JourneyArticles;
