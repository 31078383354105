import * as Yup from "yup";
export const staffValidationSchema = Yup.object().shape({
    firstname: Yup.string()
        .required('First name is required!'),
    lastname: Yup.string()
        .required('Last name is required!'),
    email: Yup.string()
        .email('E-mail is not valid!')
        .required('E-mail is required!'),
    phone: Yup.string()
        .required('Phone is required!')
        .min(6, "Enter a valid Phone Number!"),

    extCode: Yup.string()
        .nullable()
        .max(5, "Ext. code should be valid!")
        .min(1, "Ext. code should be valid!")
        .test("validity", "Ext. code should be valid!", val => val ? +val > 0 : true)
        .typeError('Ext. code should be valid!'),
    roleId: Yup.number()
        .required('Role is required!'),
    departmentId: Yup.number()
        .required('Department is required!'),
    specialityIds: Yup.array()
        .required('Speciality is required!'),
    languagesKnownIds: Yup.array()
        .required('Languages known is required!'),
    location: Yup.string()
        .required('Location is required!'),
    link: Yup.string().url()
        /*.matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter valid url!'
        )*/
        .required('Website is required!'),
    bio: Yup.string()
        .required('Bio is required!'),
    status: Yup.boolean()
        .required('Status is required!'),
})
