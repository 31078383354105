import React, { FC, Fragment } from "react";
import { JourneyTemplateStep } from "../../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import EmptyState from "../../../../../../shared/components/EmptyState";
import RichTextViewer from "../../../../../../shared/components/RichTextViewer";
import "./journeyDoctorNotes.scss";

interface JourneyDoctorNotesProps {
  event: JourneyTemplateStep;
}

const JourneyDoctorNotes: FC<JourneyDoctorNotesProps> = (props) => {
  const { event } = props;

  return (
    <div className="journey-doctor-notes">
      {event?.doctorNotes
        ? <Fragment>
          <div className="journey-doctor-notes__label">Clinical Notes</div>
          {/* <div className="journey-doctor-notes__description">
            {event?.doctorNotes}
          </div> */}
          <RichTextViewer content={event?.doctorNotes} height={300} />
        </Fragment>
        : <EmptyState />}
    </div>
  );
};

export default JourneyDoctorNotes;
