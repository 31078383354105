import { useState } from "react";
import { JourneyTemplate } from "../../models/JourneyTemplate/journeyTemplate.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationModel } from "../../models/pagination.model";
import { generatePath } from "react-router-dom";
import { JourneyTemplateCategory } from "../../models/JourneyTemplate/journeyTemplateCategory.model";

const JourneyTemplateCategoryService = () => {
  const [journeyTemplateCategoryList, setJourneyTemplateCategoryList] =
    useState<JourneyTemplateCategory[]>([]);

  const fetchJourneyTemplateCategories = (id: number) => {
    return axiosInstance
      .get(generatePath(ApiRoutes.JOURNEY_TEMPLATE_CATEGORIES, { id }))
      .then((response) => {
        const journeyCategories: any = deserialize(
          JourneyTemplateCategory,
          response.data
        );
        setJourneyTemplateCategoryList(journeyCategories);
        return journeyCategories
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const createJourneyTemplateCategory = (
    id: number,
    data: JourneyTemplateCategory,
    onSuccess?: (category: JourneyTemplateCategory) => void
  ) => {
    const serializedData = serialize(JourneyTemplateCategory, data);
    return axiosInstance
      .post(
        generatePath(ApiRoutes.JOURNEY_TEMPLATE_CATEGORIES, { id }),
        serializedData
      )
      .then((response) => {
        const journeyTemplateCategory = deserialize(
          JourneyTemplateCategory,
          response.data
        );
        setJourneyTemplateCategoryList([
          journeyTemplateCategory,
          ...journeyTemplateCategoryList,
        ]);
        Notification({
          message: "Success",
          description:
            "Journey template category has been created successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess && onSuccess(journeyTemplateCategory);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const updateJourneyTemplateCategory = (
    id: number,
    data: JourneyTemplateCategory
  ) => {
    const serializedData = serialize(JourneyTemplateCategory, data);
    return axiosInstance
      .put(
        generatePath(ApiRoutes.JOURNEY_TEMPLATE_CATEGORIES, { id }) +
        "/" +
        data.id,
        serializedData
      )
      .then((response) => {
        const journeyTemplateCategory = deserialize(
          JourneyTemplateCategory,
          response.data
        );
        Notification({
          message: "Success",
          description:
            "Journey template category has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const updateJourneyTemplateCategoryOrder = (
    id: number,
    templateCategoryId: number,
    data: JourneyTemplateCategory
  ) => {
    const serializedData = serialize(JourneyTemplateCategory, data);
    return axiosInstance
      .put(
        generatePath(ApiRoutes.JOURNEY_TEMPLATE_CATEGORIES_ORDER, {
          id,
          templateCategoryId,
        }),
        serializedData
      )
      .then((response) => {
        const journeyTemplateCategory = deserialize(
          JourneyTemplateCategory,
          response.data
        );
        Notification({
          message: "Success",
          description:
            "Journey template category order has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const deleteJourneyTemplateCategory = (
    id: number,
    journeyTemplateCategoryId: number
  ) => {
    return axiosInstance
      .delete(
        generatePath(ApiRoutes.JOURNEY_TEMPLATE_CATEGORIES, { id }) +
        "/" +
        journeyTemplateCategoryId
      )
      .then((response) => {
        Notification({
          message: "Success",
          description:
            "Journey template category has been deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => { });
  };

  return {
    journeyTemplateCategoryList,
    fetchJourneyTemplateCategories,
    createJourneyTemplateCategory,
    deleteJourneyTemplateCategory,
    updateJourneyTemplateCategory,
    updateJourneyTemplateCategoryOrder,
  };
};

export default JourneyTemplateCategoryService;
