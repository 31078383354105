import React, { FC, Fragment } from "react";
import "./patientJourneyCard.scss";
import moment from "moment";
import { Col, Dropdown, Menu, Row } from "antd";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { Journey } from "../../../models/Journey/journey.model";
import { getFullName } from "../../utils/dataFormatConverter";

interface PatientJourneyCardProps {
  status: string;
  cardType: string;
  journey?: Journey;
}

const PatientJourneyCard: FC<PatientJourneyCardProps> = (props) => {
  const { status, cardType, journey } = props;

  const history = useHistory();

  return (
    <Row
      className={`patient-journey-card`}
      onClick={() =>
        history.push(
          generatePath(appRoutes.JOURNEY_DETAILS, { id: journey?.id })
        )
      }
    >
      <div className={`card-${status}`} />
      <Col span={24}>
        <div className="patient-journey-card--title">Patient</div>
        <div className="patient-journey-card--value text-bold text-capitalize"
          onClick={(e) => {
            e.stopPropagation();
            history.push(
              generatePath(appRoutes.SHOW_PATIENT, {
                hospitalId: Number(journey?.hospital?.id),
                id: Number(journey?.patient?.id),
              })
            );
          }}
        >
          {getFullName(journey?.patient)}
        </div>
      </Col>
      <Col span={24}>
        <div className="patient-journey-card--title">Journey</div>
        <div className="patient-journey-card--value">
          {journey?.name || ""}
        </div>
      </Col>
      <Col span={16}>
        <div className="patient-journey-card--title">Caregiver</div>
        <div className="patient-journey-card--value"
          onClick={(e) => {
            e.stopPropagation();
            history.push(
              generatePath(appRoutes.SHOW_CARETAKER, {
                hospitalId: Number(journey?.hospital?.id),
                id: Number(journey?.caretaker?.id),
              })
            );
          }}
        >
          {getFullName(journey?.caretaker)}
        </div>
      </Col>
      <Col span={8}>
        {journey?.surgeryDate && <Fragment>
          <div className="patient-journey-card--title">Primary Date</div>
          <div className="patient-journey-card--value">
            {journey?.surgeryDate && moment(journey?.surgeryDate).format("MM/DD/YYYY")}
          </div>
        </Fragment>}
      </Col>
      <Col span={24}>
        <div className="patient-journey-card--title">Hospital</div>
        <div className="patient-journey-card--value">
          {journey?.hospital?.name || ""}
        </div>
      </Col>
      <div className="patient-journey-card__header">
        <span className={`patient-journey-card__status text-capitalize ${status}`}>
          <i
            className={`${status === "ongoing" ? "icon-on-going" : status === "draft" ? "icon-pending" : "icon-accepted"
              }`}
          />{" "}
          {status}
        </span>
      </div>
      {/* {cardType === "patient" ? (
        <div
          className="patient-journey-card__row"
        >
          <span className="patient-journey-card__row-icon">CG</span>
          <span className="patient-journey-card__row-content patient-journey-card__name">
            {getFullName(journey?.caretaker)}
          </span>
        </div>
      ) : (
        <div
          className="patient-journey-card__row"
        >
          <span className="patient-journey-card__row-icon">PT</span>
          <span className="patient-journey-card__row-content patient-journey-card__name">
            {getFullName(journey?.patient)}
          </span>
        </div>
      )}
      <div className="patient-journey-card__row">
        <span className={`patient-journey-card__row-icon`}>
          <span className={`icon-surgery`} />
        </span>
        <span className="patient-journey-card__row-content">
          {journey?.name}
        </span>
      </div>
      <div className="patient-journey-card__row">
        <span className={`patient-journey-card__row-icon`}>
          <span className={`icon-date`} />
        </span>
        <span className="patient-journey-card__row-content">
          {moment(journey?.surgeryDate).format("MM/DD/YYYY")}
        </span>
      </div> */}
    </Row>
  );
};

export default PatientJourneyCard;
