import { Checkbox, Space } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { DropdownModeEnum } from "../../../../../enums/dropdownMode.enum";
import { JourneyStepEntityEnum } from "../../../../../enums/journeyStepEntity.enum";
import {
  JourneyTemplateStep,
  JourneyTemplateStepEntityModel,
} from "../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import MetaService from "../../../../../services/MetaService/meta.service";
import DropdownField from "../../../../../shared/components/DropdownField";
import "./journeyTemplateStepEntitySelect.scss";
import { removeDuplicates } from "../../../../../shared/utils/dataFormatConverter";

interface JourneyTemplateStepEntitySelectProps {
  setFieldValue: Function;
  stepEntities?: JourneyTemplateStepEntityModel[];
  customizeJourney?: boolean;
  fieldName: string;
  type: JourneyStepEntityEnum;
  showField?: boolean;
  setShowField?: Function
}

type DropdownOptions = 
{
  id: number | string;
  value: string;
  className: string;
  optionLabel?: string;
  label: ReactElement;
  categoryId?: string;
  categoryName?: string;
};

type GroupedOptions = {
  label?: string;
  value?: number;
  options: DropdownOptions[];
}

function JourneyTemplateStepEntitySelect({
  setFieldValue,
  stepEntities,
  fieldName,
  type,
  showField,
  setShowField
}: JourneyTemplateStepEntitySelectProps) {
  const { articleTags, fetchArticleTags, fetchFaqTags, faqTags } =
    MetaService();

  const [dropdownOptions, setDropdownOptions] = useState<DropdownOptions[]| GroupedOptions[]>([]);

  const [value, setValue] = useState<string[]>([]);

  const handleChangeDropdown = (values: string[], options: DropdownOptions[]) => {
    setValue(values);
    setFieldValue(
      fieldName,
      options.map(({ id, className }) =>
        Object.assign(new JourneyTemplateStepEntityModel(), {
          id,
          className,
        })
      )
    );
  };

  useEffect(() => {
    if (stepEntities) {
      setValue(
        stepEntities?.map((entity) => `$${entity.className}$${entity.id}`)
      );
      setFieldValue(fieldName, stepEntities);
    }
  }, [stepEntities]);

  useEffect(() => {
    type === JourneyStepEntityEnum.ARTICLE
      ? fetchArticleTags()
      : fetchFaqTags();
  }, [type]);

  useEffect(() => {
    const articleOptions = articleTags?.articles
      ? articleTags?.articles?.map((article, i) => ({
        label: (
          <div className="journey-template-step-entity__option">
            <div className="journey-template-step-entity__title">
              {article?.title}
              <div className="journey-template-step-entity__author">
                <span className="journey-template-step-entity__author-label">
                  Published by
                </span>
                <span>{article?.authorName}</span>
              </div>
            </div>
            <div className="journey-template-step-entity__created-date">
              {article?.createdAt}
            </div>
          </div>
        ),
        optionLabel: article.title,
        id: article.id,
        value: `$${article.className}$${article.id}`,
        className: article.className,
      }))
      : [];
    const tagOptions = articleTags?.tags
      ? articleTags?.tags?.map((tag, i) => ({
        label: (
          <div className="journey-template-step-entity__option">
            <div className="journey-template-step-entity__title">
              #{tag?.name}
            </div>
          </div>
        ),
        optionLabel: tag.name,
        id: tag.id,
        value: `$${tag.className}$${tag.id}`,
        className: tag.className,
      }))
      : [];
    setDropdownOptions([...articleOptions, ...tagOptions] as DropdownOptions[]);
  }, [articleTags]);

  useEffect(() => {
    const faqOptions = faqTags?.questions
      ? faqTags?.questions?.map((question, i) => ({
        label: (
          <div className="journey-template-step-entity__option">
            <div className="journey-template-step-entity__title">
              {question?.title}
            </div>
          </div>
        ),
        optionLabel: question.title,
        id: question.id,
        categoryId: question.categoryId,
        categoryName: question.categoryName,
        value: `$${question.className}$${question.id}`,
        className: question.className,
      }))
      : [];
    const availableCategories = removeDuplicates(faqOptions.map(faq => faq.categoryId));

    const groupedFaqMeta = availableCategories.map((categoryId: number) => ({
      label: faqOptions.find(faq => faq.categoryId === categoryId)?.categoryName,
      value: categoryId,
      options: faqOptions.filter(faq => faq.categoryId === categoryId)
    }))
    setDropdownOptions(groupedFaqMeta as GroupedOptions[] );
  }, [faqTags]);

  return (
    <div className="journey-template-step-entity">
      <div className="journey-template-step-entity__label">{type}</div>
      <DropdownField
        name="priorityId"
        dropdownMatchSelectWidth
        dropdownClassName="option-group-highlight"
        optionLabelProp="optionLabel"
        options={dropdownOptions}
        mode={DropdownModeEnum.MULTIPLE}
        placeHolder={`Search by ${type === JourneyStepEntityEnum.ARTICLE ? "content" : "faq"
          } name or add by tags`}
        showArrow={true}
        showSearch
        onChange={handleChangeDropdown}
        value={value}
        dropdownRender={(menu) => (
          <React.Fragment>
            {menu}
            <div
              className="journey-template-step-entity__deselect"
              onClick={() => {
                setValue([]);
                setFieldValue(fieldName, []);
              }}
            >
              Deselect all
            </div>
          </React.Fragment>
        )}
      />
    </div>
  );
}

export default JourneyTemplateStepEntitySelect;
