import { Button, Collapse, Dropdown, Input, Menu, Modal } from "antd";
import { DownOutlined, UpOutlined, EllipsisOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import "./journeyCategories.scss";
import { useJourneyContext } from "../../../context/JourneyContext";
import JourneyCategorySteps from "../JourneyCategorySteps";
import JourneyTemplateCategoryService from "../../../services/JourneyTemplateService/journeyTemplateCategory.service";
import { useParams } from "react-router-dom";
import { JourneyTemplateCategory } from "../../../models/JourneyTemplate/journeyTemplateCategory.model";
import JourneyCategoryService from "../../../services/JourneyService/journeyCategory.service";
import { TextAreaField } from "../InputField";

interface JourneyCategoriesProps {
  editMode?: boolean;
  customizeJourney?: boolean;
}

function JourneyCategories({ customizeJourney, editMode }: JourneyCategoriesProps) {
  const {
    journeyDetails: { categories, activeJourneyCategory },
    setJourneyCategories,
    addJourneyCategory,
    setActiveJourneyCategory,
    setActiveJourneyCategoryStep,
  } = useJourneyContext();

  const [journeyOrderedCategories, setJourneyOrderedCategories] = useState([]);
  const params: any = useParams();

  const id = Number(params.id);

  const {
    fetchJourneyCategories,
    createJourneyCategory,
    updateJourneyCategory,
    deleteJourneyCategory,
    updateJourneyCategoryOrder,
    journeyCategoryList,
  } = JourneyCategoryService();

  const {
    fetchJourneyTemplateCategories,
    createJourneyTemplateCategory,
    updateJourneyTemplateCategory,
    deleteJourneyTemplateCategory,
    updateJourneyTemplateCategoryOrder,
    journeyTemplateCategoryList,
  } = JourneyTemplateCategoryService();

  const handleReorder = (e: any) => {
    const { order } = categories[e.newIndex];
    const data = Object.assign(new JourneyTemplateCategory(), {
      order: order ?? 1,
    });
    if (customizeJourney) {
      updateJourneyCategoryOrder(id, e.item.dataset.id, data).then(() =>
        handleFetchCategories()
      );
    } else {
      updateJourneyTemplateCategoryOrder(id, e.item.dataset.id, data).then(() =>
        handleFetchCategories()
      );
    }
  };

  const handleDeleteMilestone = (categoryId: number) => () => {
    Modal.confirm({
      icon: null,
      width: "40%",
      className: "ant-confirm-modal",
      title: "Delete Milestone",
      content: <div>Do you wish to delete the Milestone?</div>,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        if (customizeJourney) {
          deleteJourneyCategory(id, categoryId).then(() => {
            fetchJourneyCategories(id).then((res) => {
              if (activeJourneyCategory?.id === categoryId) {
                setActiveJourneyCategory(undefined)
                setActiveJourneyCategoryStep(undefined);
              }
            });
          });
        } else {
          deleteJourneyTemplateCategory(id, categoryId).then(() => {
            fetchJourneyTemplateCategories(id).then((res) => {
              if (activeJourneyCategory?.id === categoryId) {
                setActiveJourneyCategory(undefined)
                setActiveJourneyCategoryStep(undefined);
              }
            });
          });
        }
      },
      onCancel: () => { },
    });
  };

  const handleSubmitJourneyCategory = (
    categoryId: number | undefined,
    name: string
  ) => {
    const journeyCategory = Object.assign(new JourneyTemplateCategory(), {
      id: categoryId,
      name,
    });
    if (categoryId) {
      customizeJourney
        ? updateJourneyCategory(id, journeyCategory)
        : updateJourneyTemplateCategory(id, journeyCategory);
    } else {
      customizeJourney
        ? createJourneyCategory(id, journeyCategory, (category) => {
          // setActiveJourneyCategory(category)
          fetchJourneyCategories(id)
        }
        )
        : createJourneyTemplateCategory(id, journeyCategory, (category) => {
          // setActiveJourneyCategory(category)
          fetchJourneyTemplateCategories(id)
        }
        );
    }
  };

  const handleAddCategory = () => {
    (!categories.length || categories[0]?.name) && addJourneyCategory();
  };

  const handleFetchCategories = () => {
    customizeJourney
      ? fetchJourneyCategories(id)
      : fetchJourneyTemplateCategories(id);
  };

  useEffect(() => {
    handleFetchCategories();
  }, []);

  useEffect(() => {
    setJourneyCategories(journeyTemplateCategoryList);
  }, [journeyTemplateCategoryList]);

  useEffect(() => {
    setJourneyCategories(journeyCategoryList);
  }, [journeyCategoryList]);

  useEffect(() => {
    if (categories?.length > 0 && !activeJourneyCategory) {
      setActiveJourneyCategory(categories[0]);
      setActiveJourneyCategoryStep(categories[0][(customizeJourney ? "stepList" : "templateStepList")]?.find(step => step.id))
    }
  }, [categories]);

  if (!categories.length && !editMode && !customizeJourney && params.id !== "new")
    return null;

  return (
    <div className="journey-template-steps__parent">
      <div className="journey-categories">
        {(editMode || customizeJourney) && <Button
          className="journey-categories__add"
          type="link"
          onClick={handleAddCategory}
        >
          + Add new Milestone
        </Button>}
        <ReactSortable
          list={categories as any[]}
          setList={setJourneyOrderedCategories as any}
          onSort={handleReorder}
        >
          {categories &&
            categories.map((category, i) => (
              <div key={category?.id}>
                <Collapse
                  key={category.id}
                  bordered={false}
                  expandIcon={({ isActive }) =>
                    isActive ? <UpOutlined /> : <DownOutlined />
                  }
                  defaultActiveKey={i === 0 ? i + 1 : undefined}
                  className={`site-collapse-custom-collapse ${activeJourneyCategory?.id === category.id && "active"} ${(editMode || customizeJourney) && "editable"}`}
                  accordion
                >
                  <Collapse.Panel
                    header={
                      <div className={`journey-categories__category-label`}>
                        <span className="journey-categories__category-title">
                          {(editMode || customizeJourney)
                            ? <TextAreaField
                              defaultValue={category.name}
                              placeholder={"Enter milestone title"}
                              className="journey-categories__category-title-input"
                              onClick={(e: any) => e.stopPropagation()}
                              onKeyDown={(e: any) => {
                                const value = (e.target as any).value
                                if (!!value && e.key === "Enter") {
                                  e.target.blur()
                                  handleSubmitJourneyCategory(
                                    category.id,
                                    value
                                  );
                                }
                              }}
                            />
                            : category.name}
                        </span>
                        {(editMode || customizeJourney) && category?.id && (
                          <span
                            className="journey-categories__add-icon"
                            onClick={(e: any) => {
                              const classList: DOMTokenList = e.target?.parentNode?.parentNode?.parentNode?.classList
                              classList.contains("ant-collapse-item-active")
                                && e.stopPropagation();
                              if (category) {
                                setActiveJourneyCategory(category);
                                setActiveJourneyCategoryStep(undefined);
                              }
                            }}
                          >
                            +
                          </span>
                        )}
                        {(editMode || customizeJourney) && <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item
                                key="1"
                                onClick={
                                  category?.id
                                    ? handleDeleteMilestone(category.id)
                                    : () => setJourneyCategories(categories?.filter(cat => !!cat.id))
                                }
                              >
                                Delete
                              </Menu.Item>
                            </Menu>
                          }
                          placement="bottomLeft"
                          trigger={["click"]}
                          className="manage-groups__details--action"
                        >
                          <EllipsisOutlined
                            rotate={90}
                            className="journey-categories__ellipsis-icon"
                            onClick={(e) => e.stopPropagation()}
                          />
                        </Dropdown>}
                      </div>
                    }
                    key={i + 1}
                    className="site-collapse-custom-panel"
                  >
                    {category?.id && (
                      <JourneyCategorySteps
                        onRefresh={handleFetchCategories}
                        customizeJourney={customizeJourney}
                        editMode={editMode}
                        journeyCategory={category}
                        steps={
                          customizeJourney
                            ? category.stepList
                            : category.templateStepList
                        }
                      />
                    )}
                  </Collapse.Panel>
                </Collapse>
              </div>
            ))}
        </ReactSortable>
      </div>
    </div>
  );
}

export default JourneyCategories;
