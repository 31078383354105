/** JourneyContext is responsible for storing the Global State
 * for journey details.
 **/

import React, {
  useContext,
  createContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { JourneyTemplateCategory } from "../models/JourneyTemplate/journeyTemplateCategory.model";
import { JourneyTemplateStep } from "../models/JourneyTemplate/journeyTemplateStep.model";

// Create the context
const JourneyContext: any = createContext({});

// Define the default context state
interface IJourneyState {
  categories: JourneyTemplateCategory[];
  activeJourneyCategory?: JourneyTemplateCategory;
  activeJourneyCategoryStep?: JourneyTemplateStep;
}

const journeyState: IJourneyState = {
  categories: [],
};

type SetAuthState = Dispatch<SetStateAction<IJourneyState>>;

type AuthContentProps = [IJourneyState, SetAuthState];

// Create method to use context
function useJourneyContext() {
  const context = useContext<AuthContentProps>(JourneyContext);

  if (!context) {
    // TODO: replace with proper error handling
    throw new Error(
      `useJourneyContext must be used within a JourneyContextProvider`
    );
  }
  const [journeyDetails, setJourneyDetails] = context;

  // Context Methods //
  const setJourneyCategories = (categories: JourneyTemplateCategory[]) => {
    setJourneyDetails((journeyDetails) => {
      return {
        ...journeyDetails,
        categories,
      };
    });
  };

  const setActiveJourneyCategory = (
    activeJourneyCategory?: JourneyTemplateCategory
  ) => {
    setJourneyDetails((journeyDetails) => {
      return {
        ...journeyDetails,
        activeJourneyCategory,
      };
    });
  };

  const setActiveJourneyCategoryStep = (
    activeJourneyCategoryStep?: JourneyTemplateStep
  ) => {
    setJourneyDetails((journeyDetails) => {
      return {
        ...journeyDetails,
        activeJourneyCategoryStep,
      };
    });
  };

  const addJourneyCategory = () => {
    const categories = [
      new JourneyTemplateCategory(),
      ...journeyDetails.categories,
    ];
    setJourneyDetails((journeyDetails) => {
      return {
        ...journeyDetails,
        activeJourneyCategory: undefined,
        activeJourneyCategoryStep: undefined,
        categories,
      };
    });
  };

  const addJourneyCategoryStep = (
    journeyCategoryId: number,
    customizeJourney: boolean
  ) => {
    const { categories } = journeyDetails;
    const categoryIndex = categories.findIndex(
      (category) => category.id === journeyCategoryId
    );
    if (categoryIndex >= 0) {
      if (customizeJourney) {
        categories[categoryIndex].templateStepList?.unshift(
          Object.assign(new JourneyTemplateCategory(), {
            name: "New step",
          })
        );
      } else {
        categories[categoryIndex].stepList?.unshift(
          Object.assign(new JourneyTemplateCategory(), {
            name: "New step",
          })
        );
      }
      setJourneyDetails((journeyDetails) => {
        return {
          ...journeyDetails,
          categories: [...categories],
        };
      });
    }
  };

  // Return state and Context Methods
  return {
    journeyDetails,
    setJourneyDetails,
    setJourneyCategories,
    addJourneyCategoryStep,
    addJourneyCategory,
    setActiveJourneyCategory,
    setActiveJourneyCategoryStep,
  };
}

// Create the context provider
function JourneyContextProvider(props: any) {
  const [journeyDetails, setJourneyDetails] = useState(journeyState);
  const value = useMemo(
    () => [journeyDetails, setJourneyDetails],
    [journeyDetails]
  );
  return <JourneyContext.Provider value={value} {...props} />;
}

export { JourneyContextProvider, useJourneyContext };
