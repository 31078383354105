import React, { FC, useEffect, useState } from "react";
import "./caretakerForm.scss";
import { Form, Formik } from "formik";
import { caretakerValidationSchema } from "./caretakerFormValidation";
import InputField from "../../../../../../shared/components/InputField";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import { Modal, Slider, Switch } from "antd";
import DropdownField from "../../../../../../shared/components/DropdownField";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Caretaker } from "../../../../../../models/Patient/patient.model";
import CaretakerService from "../../../../../../services/CaretakerService/caretaker.service";
import PhoneNumberInput from "../../../../../../shared/components/PhoneNumberInput";
import { parsePhoneNumber } from "react-phone-number-input";

interface CareTakerFormProps {
  showForm: boolean;
  hospitalId: string | number;
  setShowForm: (showForm: boolean) => void;
  caretaker: Caretaker;
  refreshList?: Function;
  refreshDetails?: Function;
}

const CareTakerForm: FC<CareTakerFormProps> = (props) => {
  const { showForm, setShowForm, caretaker, refreshList, hospitalId,refreshDetails } = props;
  const history = useHistory();
  const {state} = useLocation<{showForm: boolean,currentPage: string | number}>();
  const {
    createCaretaker,
    updateCaretaker,
    deleteCaretaker,
    caretakerButtonLoading,
    checkExistingCaretaker,
    existingCaretaker,
    updateExistingCaregiver,
    caretakerCheck
  } = CaretakerService();

  const [initialValue, setInitialValue] = useState<Caretaker>(new Caretaker());

  useEffect(() => { 
    existingCaretaker && setCaretakerValues(existingCaretaker as Caretaker);
  }, [existingCaretaker]); 

  useEffect(() => {
    setCaretakerValues(caretaker)
  }, [caretaker]);

  const setCaretakerValues = (caretaker: Caretaker) => {
    setInitialValue({
      id: caretaker?.id,
      firstname: caretaker?.firstname,
      lastname: caretaker?.lastname,
      email: caretaker?.email,
      isActive: caretaker?.isActive,
      phone: caretaker?.phone ? caretaker.isdCode + caretaker?.phone : "",
    });
  }
  const onSubmit = async (
    data: Caretaker,
    formProps: { resetForm: () => void }
  ) => {
    const values = { ...data };
    if (values.phone) {
      values.isdCode = "+" + parsePhoneNumber(values.phone)
        ?.countryCallingCode as any;
      values.phone = parsePhoneNumber(values.phone)?.nationalNumber as any;
    }
    const response = caretakerCheck 
    ? await updateExistingCaregiver(hospitalId,values) 
    : values.id
    ? await updateCaretaker(hospitalId, values)
    : await createCaretaker(hospitalId, values);
    if (!response) return;
    setShowForm(false);
    formProps.resetForm();
    history.replace({
      ...history.location,
      state: {
        ...state,
        showForm: false 
      },
    });
    if (refreshList) refreshList();
    if (refreshDetails) refreshDetails(hospitalId,values.id)
  };

  return (
    <Modal
      title={null}
      visible={showForm}
      onCancel={() => {
        setShowForm(false);
      }}
      footer={null}
      maskClosable = {false}
      className="hospital-form caretaker-form"
    >
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={caretakerValidationSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          resetForm,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <div className="hospital-form__header">
              {Object.keys(caretaker).length ? "Edit" : "New"} Caregiver
            </div>
         
            <div className="hospital-form__row">
            <div className="hospital-form__field">
                <div className="form-label">Contact Number</div>
                <PhoneNumberInput
                  name={"phone"}
                  value={values.phone}
                  onChange={(value) => {
                    setFieldValue("phone", value);
                  }}
                  onBlur={async (e:any) => {
                     values.phone && checkExistingCaretaker( parsePhoneNumber(values.phone as string)?.nationalNumber as any);                
                  }}
                />
              </div>
              <div className="hospital-form__field">
                <div className="form-label">Email</div>
                <InputField
                  type="email"
                  name="email"
                  placeholder="Enter email"
                />
              </div>
             
            </div>

            <div className="hospital-form__row">
              <div className="hospital-form__field">
                <div className="form-label">First Name</div>
                <InputField
                  type="text"
                  name="firstname"
                  placeholder="Enter First Name"
                />
              </div>
              <div className="hospital-form__field">
                <div className="form-label">Last Name</div>
                <InputField
                  type="text"
                  name="lastname"
                  placeholder="Enter Last Name"
                />
              </div>
            </div>

            {values.id && (
              <div className="hospital-form__row">
                <div className="hospital-form__field">
                  <div className="form-label">Status</div>
                  <Switch
                    checked={values.isActive == "1"}
                    onChange={(checked) => {
                      setFieldValue("isActive", checked ? "1" : "0");
                    }}
                  ></Switch>
                </div>
              </div>
            )}

            <div className="hospital-form__actions">
              <ButtonComponent
                className="hospital-form__action"
                type="text"
                text="Cancel"
                onClick={() => {
                  resetForm()
                  setShowForm(false);
                }}
              />
              <ButtonComponent
                htmlType="submit"
                loading={caretakerButtonLoading}
                className="hospital-form__action"
                type="primary"
                text={Object.keys(caretaker).length ? "Update" : "Create"}
                disabled={!isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CareTakerForm;
