import React, { FC } from "react";
import Lottie from 'react-lottie';
import LoaderAnimation from "./../../../assets/lottie/empty.json"
import "./emptyState.scss"

interface EmptyStateProps {
    text?: string
}

const EmptyState: FC<EmptyStateProps> = ({
    text = "No data found...",
    children,
}) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LoaderAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return <div className="empty-state__container">
        <Lottie
            options={defaultOptions}
            height={250}
            width={250}>
            {children}
        </Lottie>
        <span className="text-body-14">
            {text}
        </span>
    </div>
}

export default EmptyState