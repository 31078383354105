import * as Yup from "yup";

export const hospitalValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required!'),
    location: Yup.string()
        .required('Location is required!'),
    email: Yup.string()
        .email('E-mail is not valid!')
        .nullable(),
    phone: Yup.string()
        .required('Phone is required!')
        .min(6, "Enter a valid Phone Number!")
        .typeError('Enter a valid Phone Number!'),
    extCode: Yup.string()
        .nullable()
        .max(5, "Ext. code should be valid!")
        .min(1, "Ext. code should be valid!")
        .test("validity", "Ext. code should be valid!", val => val ? +val > 0 : true)
        .typeError('Ext. code should be valid!'),
    website: Yup.string().url()
        /*.matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter valid url!'
        )*/
        .required('Website is required!'),
    bio: Yup.string()
        .optional(),
})
