export const statusMeta = [
    {value: 'active', label:'Active'},
    {value: 'maintenance', label:'Maintenance'},
]

export const staffStatusMeta = [
    {value: true, label:'Active'},
    {value: false, label:'Inactive'},
]

export const priorityMeta = [
    {label:'Neutral', value:'neutral'},
    {label:'Important', value:'important'},
    {label:'Very Important', value:'very_important'},
]