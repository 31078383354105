import React, { FC, useEffect, useState } from "react";
import "./staffs.scss";
import { useHistory } from "react-router";
import { generatePath, useParams } from "react-router-dom";
import * as appRoutes from "../../../../../routes/routeConstants/appRoutes";
import { Dropdown, Menu, Tag } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import TableComponent from "../../../../../shared/components/TableComponent";
import userPlaceholder from "../../../../../assets/userPlaceholder.png";
import DeleteConfirmation from "../../../../../shared/components/DeleteConfirmation";
import StaffForm from "./StaffForm";
import { MetaModel } from "../../../../../models/meta.model";
import StaffService from "../../../../../services/HospitalService/staff.service";
import { Staff } from "../../../../../models/Hospital/staff.model";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { isNaN } from "lodash";
import { getFullName } from "../../../../../shared/utils/dataFormatConverter";

interface StaffsListProps {
  fetchStaffs: Function;
  tableLoading: boolean;
  staffList: Array<Staff>;
  currentPage?: number;
  totalItems?: number;
  handlePageChange?: (page: number, pageSize?: number) => void;
}

const StaffsList: FC<StaffsListProps> = (props) => {
  const {
    tableLoading,
    fetchStaffs,
    staffList,
    currentPage,
    totalItems,
    handlePageChange,
  } = props;

  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const { buttonLoading, deleteStaff } = StaffService();

  const [showStaffForm, setShowStaffForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [currentStaff, setCurrentStaff] = useState<any>({});

  const columns = [
    {
      title: "NAME",
      key: "name",
      width: 200,
      render: (text: string, record: any) => (
        <span
        className="text-capitalize"
          onClick={() =>
            history.push(
              generatePath(appRoutes.SHOW_HOSPITAL, { id: record?.id })
            )
          }
        >
          {getFullName(record)}
        </span>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      width: 150,
      ellipsis: true,
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "phone",
      width: 220,
      className: "white-nowrap",
      render: (text: string, record: Staff) => formatPhoneNumberIntl((record?.isdCode ? record.isdCode : "") + text + (!isNaN(parseInt(record.extCode || "")) ? "#" + record.extCode : ""))
    },
    {
      title: "ROLE",
      key: "role",
      width: 100,
      render: (text: string, record: any) => (
        <span className="text-capitalize">{record?.role?.label?.replaceAll("_", " ")}</span>
      ),
    },
    {
      title: "SPECIALIZATION",
      key: "specialization",
      className: "staff-specialization",
      width: 150,
      render: (text: string, record: Staff) => (
        <span className="text-capitalize">
          {record?.specialities?.slice(0, 2)?.map((o: MetaModel) => o.label).join(", ")}
          {(record?.specialities?.length || 0) > 2 && ", +" + ((record?.specialities?.length || 0) - 2)}
        </span>
      ),
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      render: (text: string, record: any) => (
        <Tag
          key={record?.id}
          className={`app-table-tags ${record?.status ? "green" : "red"}`}
        >
          {record?.status ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: null,
      key: "action",
      render: (text: string, record: any) => (
        <Dropdown
          overlay={menu}
          placement="bottomLeft"
          trigger={["click"]}
          className="manage-groups__details--action"
          onVisibleChange={(visible) => {
            setCurrentStaff(visible ? record : {});
          }}
        >
          <EllipsisOutlined onClick={e => e.stopPropagation()} className="table-menu" />
        </Dropdown>
      ),
    },
  ];

  const refreshStaffs = () => fetchStaffs({ hospital_id: id });

  useEffect(() => {
    refreshStaffs();
  }, []);

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={(e) => {
          e.domEvent.stopPropagation()
          setShowStaffForm(true);
        }}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={(e) => {
          e.domEvent.stopPropagation()
          setShowDeleteConfirmation(true);
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="staffs">
      <TableComponent
        loading={tableLoading}
        data={staffList}
        columns={columns}
        onPageChange={handlePageChange}
        currentPage={currentPage}
        total={totalItems}
        handleRowClick={
          (record: Staff) =>
            record?.id &&
            history.push(
              generatePath(appRoutes.SHOW_STAFF, {
                id,
                staffId: record?.id,
              })
            )
        }
      />

      <DeleteConfirmation
        showConfirmation={showDeleteConfirmation}
        setShowConfirmation={setShowDeleteConfirmation}
        entityName={"staff"}
        handleDelete={() => deleteStaff(currentStaff?.id)}
        buttonLoading={buttonLoading}
        refresh={refreshStaffs}
      />
      {showStaffForm && (
        <StaffForm
          showForm={showStaffForm}
          setShowForm={setShowStaffForm}
          staff={currentStaff}
          refreshList={refreshStaffs}
        />
      )}
    </div>
  );
};

export default StaffsList;
