import React, { FC } from 'react'
import "./skeletonLoader.scss"
import { Skeleton } from 'antd';

interface SkeletonLoaderProps {
    rows?:number;
    avatar?:boolean;
}

const SkeletonLoader: FC<SkeletonLoaderProps> = (props) => {
    const { rows, avatar} = props;

    return (
        <div className="skeleton-loader">
            <Skeleton active
                      avatar={avatar}
                      paragraph={{rows: rows ?? 3}}
            />
        </div>
    )
}

export default SkeletonLoader;