import { useState } from "react";
import { Department } from "../../models/Hospital/department.model";
import { generatePath } from "react-router-dom";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationModel } from "../../models/pagination.model";
import { MetaModel } from "../../models/meta.model";

const DepartmentService = () => {

    const [department, setDepartment] = useState<Department>({});

    const [departmentList, setDepartmentList] = useState<Array<Department>>();

    const [departmentsMeta, setDepartmentsMeta] = useState<Array<MetaModel>>([]);

    const [tableLoading, setTableLoading] = useState<boolean>(false);

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const [currentDepartmentPage, setCurrentDepartmentPage] = useState<number>(1);

    const [totalDepartmentItems, setTotalDepartmentItems] = useState<number>(0);

    const fetchDepartments = (hospitalId: string, search?: string, page?: number, pageSize?: number, isMeta?: boolean) => {
        setTableLoading(true);
        let apiUrl = generatePath(ApiRoutes.HOSPITAL_DEPARTMENTS, { id: hospitalId }) +
            `?${search ? `search=${search}&` : ''}${page ? `page=${page}&page_size=${pageSize}` : ''}`;
        return axiosInstance
            .get(apiUrl)
            .then((response) => {
                const departments: any = !isMeta ? deserialize(Department, response.data['results'])
                    : deserialize(MetaModel, response.data['results']);
                const paginationData = deserialize(PaginationModel, response.data);
                if (!isMeta) setDepartmentList(departments);
                else setDepartmentsMeta([...departmentsMeta, ...departments]);
                setCurrentDepartmentPage(paginationData?.page);
                setTotalDepartmentItems(paginationData?.total);
            })
            .catch((error) => {
            })
            .finally(() => {
                setTableLoading(false)
            });
    }

    const showDepartment = (hospitalId: string, id?: string) => {
        setTableLoading(true);
        let apiUrl = generatePath(ApiRoutes.HOSPITAL_DEPARTMENTS, { id: hospitalId }) + `/${id}`
        return axiosInstance
            .get(apiUrl)
            .then((response) => {
                const department: any = deserialize(Department, response.data);
                setDepartment(department)
            })
            .catch((error) => {
            })
            .finally(() => {
                setTableLoading(false)
            });
    }

    const createDepartment = (hospitalId: string, data: Department) => {
        setButtonLoading(true);
        const departmentData = serialize(Department, data);
        return axiosInstance
            .post(generatePath(ApiRoutes.HOSPITAL_DEPARTMENTS, { id: hospitalId }), departmentData)
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Department has been created successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return true
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const updateDepartment = (hospitalId: string, data: Department, id?: string) => {
        setButtonLoading(true);
        const departmentData = serialize(Department, data);
        return axiosInstance
            .put(generatePath(ApiRoutes.HOSPITAL_DEPARTMENTS, { id: hospitalId }) + `/${id}`, departmentData)
            .then(() => {
                Notification({
                    message: "Success",
                    description: "Department has been updated successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return true
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const deleteDepartment = (hospitalId: string, id?: string) => {
        setButtonLoading(true);
        return axiosInstance
            .delete(generatePath(ApiRoutes.HOSPITAL_DEPARTMENTS, { id: hospitalId }) + `/${id}`)
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Department has been deleted successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    return {
        departmentList,
        department,
        tableLoading,
        buttonLoading,
        fetchDepartments,
        showDepartment,
        createDepartment,
        updateDepartment,
        deleteDepartment,
        currentDepartmentPage,
        totalDepartmentItems,
        departmentsMeta
    }
}

export default DepartmentService;