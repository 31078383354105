import React, { FC, useEffect, useState } from 'react'
import "./DpChip.scss"

interface DpChipProps {
    name: string
    url?: string
    size?: "small" | "large" | "mini"
}

const DpChip: FC<DpChipProps> = (props) => {

    const {
        name,
        url,
        size = "small"
    } = props

    const [names, setNames] = useState<string[]>([])

    useEffect(() => {
        setNames(prev => {
            const names = name?.split(" ") || ["", ""]
            return [names[0] || '', names[1] || ""]
        })
    }, [name])
    if (!names[0] && !names[1])
        return null

    return (
        <div className={`dp-chip ${size} ${name.split(" ")[0]?.charAt(0)?.toLowerCase()}`}>
            {url
                ? <img src={url} alt="" />
                : <span>
                    {(names[0]?.charAt(0) || "") || ""}{(names[1]?.charAt(0) || "") || ""}
                </span>}
        </div>
    )
}

export default DpChip