import { serializable, alias, primitive, object, list } from 'serializr';
import { MetaModel } from "../meta.model";
import { Location } from "./location.model";
import { Staff } from "./staff.model";

export class Department {
    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('isd_code', primitive()))
    isdCode?: number;

    @serializable(alias('extension_code', primitive()))
    extCode?: number;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('phone_number', primitive()))
    phone?: string;

    @serializable(alias('location', primitive()))
    location?: string;

    @serializable(alias('hospital_location_ids', list(primitive())))
    locationIds?: (string | undefined)[] = [];

    @serializable(alias('hospital_locations', list(object(Location))))
    locations?: Location[] = [];

    /*@serializable(alias('clinical_team_ids', list(primitive())))
    clinicalTeamIds?: (string|undefined)[]=[];

    @serializable(alias('clinical_team', list(object(Staff))))
    clinicalTeam: Staff[]=[];*/

    @serializable(alias('description', primitive()))
    description?: string;

}
