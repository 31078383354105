import {useState} from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import {ApiRoutes} from "../../routes/routeConstants/apiRoutes";
import {deserialize, serialize} from "serializr";
import {PaginationModel} from "../../models/pagination.model";
import {generatePath} from "react-router-dom";
import {ArticleCommentModel, ArticleModel} from "../../models/Article/article.model";

const ArticleCommentService = () => {

    const [commentList, setCommentList] = useState<Array<ArticleCommentModel>>([]);

    const [tableLoading, setTableLoading] = useState<boolean>(false);

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [totalItems, setTotalItems] = useState<number>(0);

    const fetchArticleComments = (articleId: number,params: {} = {}) => {
        setTableLoading(true);
        return axiosInstance
            .get(generatePath(ApiRoutes.ARTICLE_COMMENTS,{id:articleId}),{params: params})
            .then((response) => {
                const comments:any = deserialize(ArticleModel, response.data['results']);
                const paginationData = deserialize(PaginationModel,response.data);
                setCommentList([...commentList,...comments]);
                setCurrentPage(paginationData?.page);
                setTotalItems(paginationData?.total);
            })
            .catch((error) => {
            })
            .finally(() => {
                setTableLoading(false)
            });
    }

    const createArticleComment = (articleId:number,data:ArticleCommentModel) => {
        setButtonLoading(true);
        const articleCommentData = serialize(ArticleCommentModel,data);
        return axiosInstance
            .post(generatePath(ApiRoutes.ARTICLE_COMMENTS,{id:articleId}),articleCommentData)
            .then((response) => {
                const comment = deserialize(ArticleCommentModel, response.data);
                setCommentList([...commentList,comment]);
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const updateArticleComment = (data:ArticleCommentModel,articleId:number,id?:number) => {
        setButtonLoading(true);
        const articleCommentData = serialize(ArticleCommentModel,data);
        return axiosInstance
            .put(generatePath(ApiRoutes.ARTICLE_COMMENTS,{id:articleId})+`/${id}`,articleCommentData)
            .then((response) => {
                const updatedComment = deserialize(ArticleCommentModel, response.data);
                const commentIndex = commentList.findIndex(comment => comment.id === updatedComment.id);
                commentList[commentIndex] = updatedComment;
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    /*const deleteArticleComment = (articleId:number,id?:number) => {
        setButtonLoading(true);
        return axiosInstance
            .delete(generatePath(ApiRoutes.ARTICLE_COMMENTS,{id:articleId})+`/${id}`)
            .then(() => {
                Notification({
                    message: "Success",
                    description: "Comment has been deleted successfully",
                    type: NotificationTypes.SUCCESS,
                });
                setCommentList([...commentList.filter(comment => comment.id !== id)])
            })
            .catch(() => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }*/

    return{
        commentList,
        tableLoading,
        buttonLoading,
        fetchArticleComments,
        createArticleComment,
        updateArticleComment,
        //deleteArticleComment,
        currentPage,
        totalItems
    }
}

export default ArticleCommentService;