import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "./ForgotPasswordValidation";
import UserService from "../../../services/AuthService/auth.service";
import { withRouter } from "react-router-dom";
import "../auth.scss";
import { useHistory } from "react-router";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { User } from "../../../models/user.model";
import { LOGIN } from "../../../routes/routeConstants/appRoutes";

const initialValue = {
  email: "",
};

const ForgotPasswordForm = () => {
  const { user, error, loading, forgotPassword } = UserService();

  const history = useHistory();

  const onSubmit = (user: User) => {
    const data = {
      email: user?.email,
      host: `http://localhost:3000/auth/reset-password`,
    };
    forgotPassword(data);
  };

  return (
    <div>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <div className="auth-form__header">Forgot Password</div>
          <div className="auth-form__field">
            <div className="form-label">Username</div>
            <InputField
              type="email"
              name="email"
              placeholder="Enter username"
            />
          </div>
          <div className="auth-form__forgot">
            <div onClick={() => history.push(LOGIN)}>Go back to login</div>
          </div>
          <ButtonComponent
            htmlType="submit"
            loading={loading}
            className="auth-form__action"
            type="primary"
            text="Submit"
          />
        </Form>
      </Formik>
    </div>
  );
};

export default withRouter(ForgotPasswordForm);
