import { Button, Modal } from "antd";
import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./cropperModal.scss";

interface CropperModalProps {
  showModal: boolean;
  closeForm: () => void;
  setFieldValue: Function;
  image?: string;
}

function CropperModal({
  showModal,
  closeForm,
  image,
  setFieldValue,
}: CropperModalProps) {
  const [cropper, setCropper] = useState<Cropper>();

  const handleCrop = () => {
    if (typeof cropper !== "undefined") {
      setFieldValue(
        "bannerImageBase64Url",
        cropper.getCroppedCanvas().toDataURL()
      );
      closeForm();
    }
  };

  return (
    <Modal
      title={null}
      footer={null}
      width="60%"
      visible={showModal}
      maskClosable={false}
      onCancel={closeForm}
      className="faq-category-form cropper-modal-form"
    >
      <Cropper
        style={{ height: 400, width: "100%" }}
        zoomTo={0}
        aspectRatio={24 / 9}
        preview=".img-preview"
        src={image}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false}
        onInitialized={(instance: Cropper) => {
          setCropper(instance);
        }}
        guides={true}
      />
      <div className="cropper-modal-form__actions">
        <Button type="primary" onClick={handleCrop}>
          {" "}
          Crop
        </Button>
      </div>
    </Modal>
  );
}

export default CropperModal;
