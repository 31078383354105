import React, { FC } from 'react'
import "./datepickerField.scss"
import { DatePicker } from "antd";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";
import moment, { Moment } from "moment";

interface DatepickerFieldProps {
    name: string;
    title?: string;
    setFieldValue?: Function;
    placeholder?: string;
    value?: any;
    onChange?: (value: any, dateString: any) => void;
    disabled?: boolean;
    className?: string;
    startValue?: string;
    format?: string;
    showTime?: boolean
    disableDates?: "future" | "past";
}

const DatepickerField: FC<DatepickerFieldProps> = (props) => {
    const {
        name,
        title,
        setFieldValue,
        placeholder,
        onChange,
        value,
        disabled,
        className,
        showTime = true,
        disableDates,
        startValue,
        format = "YYYY-MM-DD HH:mm:ss",
    } = props;

    function range(start: number, end: number) {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    function disabledDateTime(current: any) {
        if (current < moment(startValue)) {
            const startValueHours = moment(startValue).hours();
            const startValueMinutes = moment(startValue).minutes();
            return {
                disabledHours: () => range(0, startValueHours),
                disabledMinutes: () => range(0, startValueMinutes + 1),
                disabledSeconds: () => [],
            };
        }
        return {
            disabledHours: () => range(0, 0),
            disabledMinutes: () => range(0, 0),
            disabledSeconds: () => [],
        };
    }

    const disableDatesHandler = (current: Moment) => {
        return (
          current &&
          (startValue
            ? current < moment(startValue).startOf('day')
            : disableDates === 'future'
            ? !current.isBefore(moment())
            : current.isBefore(moment()))
        );
    };

    return (
        <Field name={name}>
            {({
                field,
                form: { touched, errors, setFieldTouched },
                meta,
            }: any) => {
                return (
                    <div className={`datepicker-field`}>
                        {title && (
                            <div className="dropdown-field__title">{title}</div>
                        )}
                        <DatePicker
                            format={format}
                            disabledDate={disableDatesHandler}
                            // disabledDate={disabledDate}
                            disabledTime={disabledDateTime}
                            showTime={showTime && { defaultValue: moment('00:00:00', 'HH:mm:00') }}
                            placeholder={placeholder}
                            //suffixIcon={<i className='icon-date'/>}
                            onChange={onChange}
                            value={value}
                        />
                        <ErrorMessage name={name}>
                            {(message: string) => (
                                // @ts-ignore
                                <Error className={`${name}__error`}
                                    message={message}
                                />
                            )}
                        </ErrorMessage>
                    </div>
                );
            }}
        </Field>
    )
}

export default DatepickerField;