import { useState } from "react";
import { Hospital } from "../../models/Hospital/hospital.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationModel } from "../../models/pagination.model";

const HospitalService = () => {

    const [hospital, setHospital] = useState<Hospital>(new Hospital());

    const [hospitalList, setHospitalList] = useState<Array<Hospital>>([]);

    const [tableLoading, setTableLoading] = useState<boolean>(false);

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [totalItems, setTotalItems] = useState<number>(0);

    const fetchHospitals = async (search?: string, page?: number, pageSize?: number, isMeta?: boolean) => {
        setTableLoading(true);
        try {
            let apiUrl = ApiRoutes.HOSPITALS +
                `?${search ? `search=${search}&` : ''}${page ? `page=${page}&page_size=${pageSize}` : ''}${isMeta? "&metadata=metadata": ""}`;
            return axiosInstance
                .get(apiUrl)
                .then((response)=> {
                    const hospitals: any = deserialize(Hospital, isMeta? response.data: response.data['results']);
                    const paginationData = deserialize(PaginationModel, response.data);
                    setHospitalList(hospitals);
                    setCurrentPage(paginationData?.page);
                    setTotalItems(paginationData?.total);
                })
        } catch (error) {

        } finally {
            setTableLoading(false)
        }
    }

    const showHospital = (id?: string) => {
        setTableLoading(true)
        return axiosInstance
            .get(ApiRoutes.HOSPITALS + `/${id}`)
            .then((response) => {
                const hospital = deserialize(Hospital, response.data);
                setHospital(hospital);
            })
            .catch((error) => {
            })
            .finally(() => {
                setTableLoading(false)
            });
    }

    const createHospital = (data: Hospital) => {
        setButtonLoading(true);
        const hospitalData = serialize(Hospital, data);
        return axiosInstance
            .post(ApiRoutes.HOSPITALS, hospitalData)
            .then((response) => {
                const hospital = deserialize(Hospital, response.data);
                setHospitalList([...hospitalList, hospital]);
                Notification({
                    message: "Success",
                    description: "Hospital has been created successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return hospital
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const updateHospital = (data: Hospital, id?: string) => {
        setButtonLoading(true);
        const hospitalData = serialize(Hospital, data);
        return axiosInstance
            .put(ApiRoutes.HOSPITALS + `/${id}`, hospitalData)
            .then((response) => {
                const hospital = deserialize(Hospital, response.data);
                Notification({
                    message: "Success",
                    description: "Hospital has been updated successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return hospital
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const deleteHospital = (id?: string) => {
        setButtonLoading(true);
        return axiosInstance
            .delete(ApiRoutes.HOSPITALS + `/${id}`)
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Hospital has been deleted successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    return {
        hospital,
        hospitalList,
        tableLoading,
        buttonLoading,
        fetchHospitals,
        showHospital,
        createHospital,
        updateHospital,
        deleteHospital,
        currentPage,
        totalItems
    }
}

export default HospitalService;