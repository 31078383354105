import { Col, Form, Modal, Row } from "antd";
import { Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import React, { Dispatch, FC, SetStateAction, useRef } from "react";
import { deserialize } from "serializr";
import { MetaModel } from "../../../models/meta.model";
import FaqService from "../../../services/FaqService/faq.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import InputField from "../../../shared/components/InputField";
import "./faqCategoryForm.scss";

interface FaqCategoryFormProps {
  visible: boolean;
  setActiveCategoryId: Function
  closeHandler: (res?: boolean) => void;
  activeCategory?: MetaModel;
}

const FaqCategoryForm: FC<FaqCategoryFormProps> = (props) => {
  const { visible, closeHandler, activeCategory, setActiveCategoryId } = props;

  const formikRef = useRef<FormikProps<any>>(null);

  const { buttonLoading, createCategories, updateCategories } = FaqService();

  const handleSubmit = async (
    values: FormikValues,
    helpers: FormikHelpers<any>
  ) => {
    values.name = values.name.toLowerCase();
    const category = deserialize(MetaModel, values);
    const response = activeCategory
      ? await updateCategories(category)
      : await createCategories(values.name);
    if (!response) return;
    response.id && setActiveCategoryId(response.id)
    helpers.resetForm();
    closeHandler(true);
  };

  const closeForm = () => {
    formikRef.current?.resetForm();
    closeHandler();
  };

  return (
    <Modal
      title={null}
      footer={null}
      visible={visible}
      onCancel={closeForm}
      maskClosable = {false}
      className="faq-category-form"
    >
      <div>
        <h2 className="mb-5">
          {activeCategory?.label ? "Edit category" : "Add new category"}
        </h2>
        <Formik
          enableReinitialize
          initialValues={{
            name: activeCategory?.label ?? "",
            id: activeCategory?.value,
          }}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {({ values, submitForm }) => (
            <Form>
              <Row justify="end">
                <Col span={24}>
                  <InputField
                    type={"text"}
                    name={"name"}
                    placeholder={"Category name"}
                  />
                </Col>
                <Col className="mt-5 form-actions">
                  <ButtonComponent
                    key="faq-cancel"
                    type="text"
                    loading={false}
                    text={"Cancel"}
                    onClick={closeForm}
                  />
                  <ButtonComponent
                    key="faq-save"
                    type="primary"
                    htmlType="submit"
                    loading={buttonLoading}
                    text={activeCategory ? "Update" : "Create"}
                    onClick={submitForm}
                    disabled={!values.name}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default FaqCategoryForm;
