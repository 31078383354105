import axios from 'axios';
import { store } from "../store";
import { REQUEST_LOGOUT } from "../store/definitions/authConstants";
import { ApiRoutes } from "../routes/routeConstants/apiRoutes";
import Notification from "../shared/components/Notification";
import { NotificationTypes } from "../enums/notificationTypes";
import { User } from "../models/user.model";

export const getHeaders = (): any => {
    let headers, user: User;
    if (localStorage.getItem('user')) {
        user = JSON.parse(localStorage.getItem('user') || '');
        headers = {
            'Content-Type': 'application/json',
            'Authorization': user?.accessToken ?? '',
        };
    }
    return headers;
};

const axiosInstance = axios.create({
    baseURL: ApiRoutes.BASE_URL,
    timeout: 40000,
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers = getHeaders();
    return config;
});

axiosInstance.interceptors.response.use(
    (response): any => {
        return {
            data: response.data,
            message: response.statusText,
            status: response.status,
        };
    },
    (error) => {
        const { response } = error;
        Notification({
            type: response.status === 401? NotificationTypes.INFO : NotificationTypes.ERROR,
            message: response.status === 401? "Session Timed out!" : "Failed",
            description:
            response?.data?.errors?.length ? (response?.data?.errors[0] === "Unauthorized!"? "Login again" : response?.data?.errors[0] ): "Something went wrong. Please try again",
        });
        if (response?.status === 401) {
            localStorage.clear()
            store.dispatch({ type: REQUEST_LOGOUT, payload: "Request for logout" });
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
