import * as Yup from "yup";

export const templateStepValidationSchema = Yup.object().shape({
  title: Yup.string().required("Step name is required!"),

  // scheduleDays: Yup.number()
  //   .positive("Schedule days must be positive!")
  //   .required("Schedule days is required!"),

  // schedule: Yup.string().required("Schedule is required!"),

  description: Yup.string().required("Description is required!"),
});

export const journeyStepValidationSchema = Yup.object().shape({
  title: Yup.string().required("Step name is required!"),

  stepTime: Yup.string().nullable(),

  description: Yup.string().required("Description is required!"),
});
