import React from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';
type Props = {
    route ?: RouteProps;
    showText?: boolean
}
interface RouteProps {
    pathname :string;
    search?:string;
    hash?: string;
    state?:StateProps;
}
interface StateProps {
    id?: string|number;
    currentPage ?: string|number;
    hospitalPage?: string | number;
    selectedKey?: string
    search?: string
}
function GoBack({route,showText}: Props) {
    const history = useHistory();
  return (
    <div
        className="go-back"
        onClick={() => route? history.push(route) : history.goBack() }
      >
        <ArrowLeftOutlined /> {showText && " Back"}
      </div>
  )
}
export default GoBack