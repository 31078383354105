import { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationModel } from "../../models/pagination.model";
import { generatePath } from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { ArticleModel } from "../../models/Article/article.model";
import { useHistory } from "react-router";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";

const ArticleService = () => {

    const history = useHistory();

    const [article, setArticle] = useState<ArticleModel>();

    const [articleList, setArticleList] = useState<Array<ArticleModel>>([]);

    const [tableLoading, setTableLoading] = useState<boolean>(false);

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [totalItems, setTotalItems] = useState<number>(0);

    const fetchArticles = (params: {} = {}) => {
        setTableLoading(true);
        return axiosInstance
            .get(ApiRoutes.ARTICLES, { params: params })
            .then((response) => {
                const articles: any = deserialize(ArticleModel, response.data['results']);
                const paginationData = deserialize(PaginationModel, response.data);
                setArticleList(articles);
                setCurrentPage(paginationData?.page);
                setTotalItems(paginationData?.total);
            })
            .catch((error) => {
            })
            .finally(() => {
                setTableLoading(false)
            });
    }

    const createArticle = (data: ArticleModel, disableNotification?: boolean) => {
        setButtonLoading(true);
        const articleData = serialize(ArticleModel, data);
        const articleFormData = convertJSONToFormData(articleData);
        return axiosInstance
            .post(ApiRoutes.ARTICLES, articleFormData)
            .then((response) => {
                const article = deserialize(ArticleModel, response.data);
                setArticleList([...articleList, article]);
                !disableNotification && Notification({
                    message: "Success",
                    description: "Content has been created successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return article
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const showArticle = (id?: string) => {
        setTableLoading(true)
        return axiosInstance
            .get(ApiRoutes.ARTICLES + `/${id}`)
            .then((response) => {
                const article = deserialize(ArticleModel, response.data);
                setArticle(article);
            })
            .catch((error) => {
            })
            .finally(() => {
                setTableLoading(false)
            });
    }

    const updateArticle = (data: ArticleModel, id: string, disableNotification?: boolean) => {
        setButtonLoading(true);
        const articleData = serialize(ArticleModel, data);
        const articleFormData = convertJSONToFormData(articleData);
        return axiosInstance
            .put(ApiRoutes.ARTICLES + `/${id}`, articleFormData)
            .then((response) => {
                const data = deserialize(ArticleModel, response.data)
                setArticle(data)
                !disableNotification && Notification({
                    message: "Success",
                    description: "Content has been updated successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return data
                // history.push(generatePath(appRoutes.ARTICLE_VIEW, { id: id }))
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const deleteArticle = (id?: number) => {
        setButtonLoading(true);
        return axiosInstance
            .delete(ApiRoutes.ARTICLES + `/${id}`)
            .then(() => {
                Notification({
                    message: "Success",
                    description: "Content has been deleted successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return true
            })
            .catch(() => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const removeCoverPicture = (id?: number) => {
        return axiosInstance
            .delete(generatePath(ApiRoutes.ARTICLE_COVER_PICTURE, { id }))
            .then(() => {
                Notification({
                    message: "Success",
                    description: "Cover picture has been removed successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch(() => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    return {
        article,
        articleList,
        tableLoading,
        buttonLoading,
        fetchArticles,
        createArticle,
        deleteArticle,
        showArticle,
        updateArticle,
        removeCoverPicture,
        currentPage,
        totalItems
    }
}

export default ArticleService;