import React, { FC, useEffect, useState } from 'react'
import "./assignedPatients.scss"
import PatientService from "../../../../../../../services/PatientService/patient.service";
import { Patient } from "../../../../../../../models/Patient/patient.model";
import userPlaceholder from "../../../../../../../assets/userPlaceholder.png";
import { generatePath, useParams } from "react-router-dom";
import * as appRoutes from "../../../../../../../routes/routeConstants/appRoutes";
import { Menu, Tag } from "antd";
import { useHistory } from "react-router";
import TableComponent from "../../../../../../../shared/components/TableComponent";
import moment from "moment";
import AuthContainer from '../../../../../../../store/container/AuthContainer';
import { AuthReducerProps } from '../../../../../../../store/reducers/authReducer';
import AppHeader from '../../../../../../../shared/components/AppHeader';
import { getFullName } from '../../../../../../../shared/utils/dataFormatConverter';

interface AssignedPatientsProps extends AuthReducerProps {

}

const AssignedPatients: FC<AssignedPatientsProps> = (props) => {
    const { user, } = props;

    const history = useHistory();

    const {id, staffId}= useParams<{id: string, staffId: string}>()
    const { patientTableLoading, fetchStaffPatients, patientList,
        patientCurrentPage, patientTotalItems } = PatientService();

    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
        staffId && fetchStaffPatients(staffId).then();
    }, [])

    const handlePageChange = (page: number, pageSize?: number) =>
        staffId && fetchStaffPatients(staffId, {
            search: searchText
        }, page, pageSize);

    const handleSearch = (searchText: string) => {
        setSearchText(searchText);
        staffId && fetchStaffPatients(staffId, {
            search: searchText
        }).then()
    }

    const columns = [
        {
            title: 'PATIENT NAME',
            key: 'name',
            render: (text: string, record: Patient) => (
                <>
                    <span>
                        {getFullName(record)}
                    </span>
                </>
            )
        },
        {
            title: 'SEX',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: 'DOB',
            dataIndex: 'dob',
            key: 'dob',
            render: (text: string, record: Patient) => record?.dob ? moment(record?.dob).format('MMMM DD,yyyy') : ''
        },
        {
            title: 'STATUS',
            key: 'status',
            dataIndex: 'status',
            render: (text: string, record: any) => (
                <Tag key={record?.id}
                    className={`app-table-tags ${record?.status ? "green" : "lime"}`}
                >
                    {record?.status ? 'Active' : 'Idle'}
                </Tag>
            )
        },
        /*{
            title: null,
            key: 'action',
            render: (text:string, record:any) => (
                <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}
                          className='manage-groups__details--action'
                          //onVisibleChange={(visible)=>{setCurrentPatient( visible ? record : {})}}
                >
                    <EllipsisOutlined className="table-menu"/>
                </Dropdown>
            ),
        },*/
    ];

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => {
                //setShowPatientForm(true)
            }}
            >
                Edit
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="assigned-patients">
            <AppHeader title={"Assigned patients"}
                isSearchEnabled={true}
                handleSearch={handleSearch}
            />

            <TableComponent loading={patientTableLoading}
                data={patientList}
                columns={columns}
                onPageChange={handlePageChange}
                currentPage={patientCurrentPage}
                total={patientTotalItems}
                handleRowClick={
                    (record: Patient) =>
                        record?.id &&
                        history.push(
                            generatePath(appRoutes.SHOW_PATIENT, {
                                hospitalId: id,
                                id: record?.id,
                            })
                        )
                }
            />
        </div>
    )
}

export default AuthContainer(AssignedPatients);