import React, { FC } from "react";
import "./deleteConfirmation.scss";
import { Modal } from "antd";
import ButtonComponent from "../ButtonComponent";

interface DeleteConfirmationProps {
  showConfirmation: boolean;
  setShowConfirmation: (showConfirmation: boolean) => void;
  entityName: string;
  subEntityName?: string;
  handleDelete: Function;
  buttonLoading: boolean;
  refresh?: Function;
}

const DeleteConfirmation: FC<DeleteConfirmationProps> = (props) => {
  const {
    entityName,
    showConfirmation,
    setShowConfirmation,
    handleDelete,
    buttonLoading,
    refresh,
    subEntityName,
  } = props;

  const onDelete = () => {
    handleDelete().then(() => {
      if (refresh) refresh();
      setShowConfirmation(false);
    });
  };

  return (
    <Modal
      title={null}
      footer={null}
      visible={showConfirmation}
      onCancel={() => setShowConfirmation(false)}
      className="delete-confirmation"
      maskClosable = {false}
    >
      <div className="delete-confirmation__header">Delete {entityName}</div>
      <div className="delete-confirmation__content">
        Do you want to delete this {entityName} {subEntityName}?
      </div>
      <div className="delete-confirmation__actions">
        <ButtonComponent
          className="delete-confirmation__action"
          type="text"
          text="Cancel"
          onClick={() => setShowConfirmation(false)}
        />
        <ButtonComponent
          loading={buttonLoading}
          className="delete-confirmation__action"
          type="primary"
          text="Confirm"
          onClick={onDelete}
        />
      </div>
    </Modal>
  );
};

export default DeleteConfirmation;
