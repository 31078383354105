import {serializable, alias, primitive, object} from 'serializr';
import {MetaModel} from "../meta.model";

export class Location {
    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('id', primitive()))
    key?: string;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('location', primitive()))
    location?: string;

    @serializable(alias('status', primitive()))
    status?: string;

}
