import React, { FC, useState } from "react";
import "./patientHeader.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { Caretaker, Patient } from "../../../models/Patient/patient.model";
import userPlaceholder from "../../../assets/userPlaceholder.png";
import { generatePath } from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import PatientDetails from "../../../views/Patients/PatientDetails";
import moment from "moment";
import SkeletonLoader from "../SkeletonLoader";
import { getAgeText } from "../../utils/dateHelpers";
import DpChip from "../DpChip";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import GoBack from "../GoBack";
import { User } from "../../../models/user.model";
import PatientService from "../../../services/PatientService/patient.service";
import CaretakerService from "../../../services/CaretakerService/caretaker.service";
import CareTakerForm from "../../../views/Hospitals/HospitalDetails/StaffsAndPatients/CaretakerList/CaretakerForm";
import PatientForm from "../../../views/Hospitals/HospitalDetails/StaffsAndPatients/PatientsList/PatientForm";

interface PatientHeaderProps {
  patient: Patient;
  hospitalId?:string;
  dependentType: string;
  dependents?: Array<Patient | Caretaker>;
  loading: boolean;
  fetchPatient: (hospitalId: string, id: string) => void
}

const PatientHeader: FC<PatientHeaderProps> = (props) => {
  const { hospitalId, patient, dependentType, dependents, loading, fetchPatient } = props;

  const history = useHistory();

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showPatientForm, setShowPatientForm] = useState<boolean>(false);
  const [showCaretakerForm, setShowCaretakerForm] = useState<boolean>(false);

  return (
    <div className="patient-header">
      <PatientDetails
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        patient={patient}
      />
      {loading ? (
        <SkeletonLoader rows={4} />
      ) : (
        <>
          <div className="patient-header__title">
            <GoBack />
            <div className="patient-header__patient-details">
              <div className="patient-header__image">
                {/* <img
                  src={userPlaceholder}
                  alt=""
                /> */}
                <DpChip name={patient?.fullname ??
                  patient?.firstname + " " + patient?.lastname}
                  url={patient.displayPictureUrl}
                />
              </div>
              <div className="patient-header__basic-info">
                <div className="patient-header__name text-capitalize">
                  {patient?.fullname ??
                    patient?.firstname + " " + patient?.lastname}
                  {dependentType === "caretaker" && (
                    <span
                      className="patient-header__view-more"
                      onClick={() => setShowDetails(true)}
                    >
                      View details
                    </span>
                  )}
                </div>
                {!!patient.dob && !!patient.gender && (
                  <div className="patient-header__age text-capitalize">
                    {getAgeText(patient?.dob)}, {patient?.gender ?? "-"}
                  </div>
                )}
                {!!patient.dob && (
                  <div className="patient-header__age text-capitalize">
                    DOB : {moment(patient?.dob).format("DD/MM/YYYY")}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="hospital-details__communication patient-header__communication">
            {patient?.email && (
              <div className="hospital-details__contact">
                <span className="hospital-details__contact-icon">
                  <i className="icon-email" />
                </span>
                <span
                  className="hospital-details__contact-info cursor-pointer"
                  onClick={(e) => {
                    window.location.href = `mailto:${patient?.email}`;
                    e.preventDefault();
                  }}
                >
                  {patient?.email}
                </span>
              </div>
            )}
            {patient?.phone && (
              <div className="hospital-details__contact">
                <span className="hospital-details__contact-icon">
                  <i className="icon-mobile" />
                </span>
                <span className="hospital-details__contact-info">
                  {formatPhoneNumberIntl((patient?.isdCode ? patient.isdCode : "") + patient.phone)}
                </span>
              </div>
            )}
            {patient?.languagesKnown && patient?.languagesKnown?.length > 0 && (
              <div className="hospital-details__contact">
                <span className="hospital-details__contact-icon">
                  <i className="icon-language" />
                </span>
                <span className="hospital-details__contact-info text-capitalize">
                  {patient?.languagesKnown?.join(", ")}
                </span>
              </div>
            )}
          </div>

          <div className="patient-header__dependents">
            {!!dependents?.length && (
              <div className="patient-header__dependents__title text-capitalize">
                {dependentType}
              </div>
            )}
            <div className="patient-header__dependents__cards">
              {dependents?.map((dependent, i) => (
                <div
                  className="patient-header__dependent"
                  onClick={() => {
                    dependentType === "caretaker"
                      ? history.push(
                        generatePath(appRoutes.SHOW_CARETAKER, {
                          id: dependent?.id,
                        })
                      )
                      : history.push(
                        generatePath(appRoutes.SHOW_PATIENT, {
                          id: dependent?.id,
                        })
                      );
                  }}
                  key={i}
                >
                  <div className="patient-header__dependent__image">
                    {/* <img
                      src={userPlaceholder}
                      alt=""
                    /> */}
                    <DpChip name={!(dependent instanceof Caretaker)
                      ? dependent?.fullname || ""
                      : dependent?.firstname + " " + dependent?.lastname}
                      url={dependent.displayPictureUrl}
                    />
                  </div>
                  <div className="patient-header__dependent__basic-info">
                    <div className="patient-header__dependent__name text-capitalize">
                      {!(dependent instanceof Caretaker)
                        ? dependent?.fullname
                        : dependent?.firstname + " " + dependent?.lastname}
                    </div>
                    {!!dependent.dob && !!dependent.gender && (
                      <div className="patient-header__age text-capitalize">
                        {getAgeText(dependent?.dob)}, {dependent?.gender ?? "-"}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <span
          className="patient-header__edit"
          onClick={() => {
           dependentType === 'patient'? setShowCaretakerForm(true): setShowPatientForm(true);
          }}
        >
          <i className="icon-edit-1" />
        </span>
      <PatientForm showForm={showPatientForm}
                setShowForm={setShowPatientForm}
                patient={patient}
                hospitalId={hospitalId || ''}
                refreshDetails={fetchPatient}
            />

      <CareTakerForm
        showForm={showCaretakerForm}
        setShowForm={setShowCaretakerForm}
        caretaker={patient}
        hospitalId={hospitalId || ''}
        refreshDetails={fetchPatient} 
        />
    </div>
    
  );
};

export default PatientHeader;
