import React, { FC, useEffect } from "react";
import "./caretakers.scss";
import PatientHeader from "../../shared/components/PatientHeader";
import { useParams } from "react-router-dom";
import CaretakerService from "../../services/CaretakerService/caretaker.service";
import PatientService from "../../services/PatientService/patient.service";
import JourneyService from "../../services/JourneyService/journey.service";
import PatientJourneyHistory from "../../shared/components/PatientJourneyHistory";

interface CaretakersProps {}

const Caretakers: FC<CaretakersProps> = (props) => {
  const { id, hospitalId } = useParams<{ id: string; hospitalId: string }>();

  const { caretaker, showCaretaker, caretakerTableLoading } =
    CaretakerService();

  const { patientList, fetchPatients } = PatientService();

  const { fetchJourneys, journeyList, tableLoading } = JourneyService();

  useEffect(() => {
    if (id) {
      showCaretaker(hospitalId, id);
      fetchPatients(hospitalId, { guardian_id: id });
      fetchJourneys({ guardian_id: id });
    }
  }, [id]);

  return (
    <div className="app-wrapper caretakers">
      <PatientHeader
        patient={caretaker}
        hospitalId={hospitalId}
        dependentType={"patient"}
        dependents={patientList}
        loading={caretakerTableLoading}
        fetchPatient={showCaretaker}
      />
      <PatientJourneyHistory
        journeyList={journeyList}
        cardType={"caretaker"}
        loading={tableLoading}
      />
    </div>
  );
};

export default Caretakers;
