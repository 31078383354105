import { Staff } from "../../models/Hospital/staff.model";
import { Caretaker, Patient } from "../../models/Patient/patient.model";

export const buildFormData = (formData: any, data: any, parentKey?: any) => {
  if (Array.isArray(data)) {
    data.forEach((dataItem: any) => {
      formData.append(`${parentKey}`, dataItem);
    });
  } else {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key: any) => {
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data === null || data === undefined ? "" : data;
      formData.append(parentKey, value);
    }
  }
};

export function convertJSONToFormData(data: any) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export function removeDuplicates(data: any) {
  const map = new Map();
  return data.filter((item: any) => {
    if (map.has(item.value)) {
      return false;
    } else {
      map.set(item.value, true);
      return true;
    }
  });
}

export function dataURItoFile(dataURI: string, fileName: string): File {
  var byteString = atob(dataURI.split(",")[1]);

  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: mimeString });

  return new File([blob], fileName);
}

export function getFullName(person: Staff | Patient | Caretaker | undefined) {
  return person
  ?  ((person instanceof Staff && person.title !== null )
  ?  person.title + " " + person.firstname + " " + person.lastname 
  :  person.firstname + " " + person.lastname )
  :  "-";
}

export function getFileType(attachmentName: string) {
  const fileNameArray = attachmentName.split(".");
  const fileType = fileNameArray[fileNameArray.length - 1];
  switch (fileType) {
    case "png":
    case "jpeg":
    case "jpg":
      return "image";
    case "pdf":
      return "pdf";
    case "xls":
    case "xlsx":
      return "xlsx";
    default:
      return "image";
  }
}
