import React, { FC, useState } from "react";
import "./journeyTemplateCarouselBig.scss";
//@ts-ignore
import ItemsCarousel from "react-items-carousel";
import carouselPlaceholder from "../../../../../../assets/surgery.png";
import { Modal } from "antd";
import { JourneyTemplateAttachment } from "../../../../../../models/JourneyTemplate/journeyTemplateAttachment.model";

interface JourneyTemplateCarouselBigProps {
  visible: boolean;
  setVisible: Function;
  imageList: Array<JourneyTemplateAttachment>;
}

const JourneyTemplateCarouselBig: FC<JourneyTemplateCarouselBigProps> = (
  props
) => {
  const { visible, setVisible, imageList } = props;

  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const chevronWidth = 40;

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      title={null}
      className={"journey-template-carousel-big__modal"}
    >
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={1}
        gutter={150}
        leftChevron={
          imageList[activeItemIndex - 1] && <div className="journey-template-carousels__navigator left" />
        }
        rightChevron={
          imageList[activeItemIndex + 1] && <div className="journey-template-carousels__navigator right" />
        }
        outsideChevron
        chevronWidth={chevronWidth}
        alwaysShowChevrons={true}
      >
        {[...imageList]?.map((x, i) => (
          <div className="journey-template-carousels__image" key={i}>
            <img src={x?.attachmentUrl} alt="" />
          </div>
        ))}
      </ItemsCarousel>
      <div className="journey-template-carousels__description">
        {imageList[activeItemIndex]?.description ?? ""}
      </div>
    </Modal>
  );
};

export default JourneyTemplateCarouselBig;
