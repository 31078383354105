import React from "react";
import AppRoutes from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";
import "antd/dist/antd.css";
import "../src/styles/_main.scss";
import { JourneyContextProvider } from "./context/JourneyContext";

const App = () => {
  return (
    <Provider store={store}>
      <JourneyContextProvider>
        <div className="App">
          <AppRoutes />
        </div>
      </JourneyContextProvider>
    </Provider>
  );
};

export default App;
