import { useState } from "react";
import { JourneyTemplateChecklist } from "../../models/JourneyTemplate/journeyTemplateChecklist.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router-dom";

const JourneyChecklistService = () => {
  const [journeyChecklist, setJourneyChecklist] =
    useState<JourneyTemplateChecklist>(new JourneyTemplateChecklist());

  const [journeyChecklistItems, setJourneyChecklistItems] = useState<
    Array<JourneyTemplateChecklist>
  >([]);

  const [checklistButtonLoading, setChecklistButtonLoading] =
    useState<boolean>(false);

  const [checklistLoading, setChecklistLoading] = useState<boolean>(false);

  const fetchJourneyChecklists = (templateStepId?: number) => {
    setChecklistLoading(true);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_STEP_CHECKLISTS, {
      id: templateStepId,
    });
    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const journeyChecklists: any = deserialize(
          JourneyTemplateChecklist,
          response.data
        );
        setJourneyChecklistItems(journeyChecklists);
      })
      .catch((error) => { })
      .finally(() => {
        setChecklistLoading(false);
      });
  };

  const createJourneyChecklist = (
    data: JourneyTemplateChecklist,
    templateStepId?: number
  ) => {
    setChecklistButtonLoading(true);
    const serializedData = serialize(JourneyTemplateChecklist, data);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_STEP_CHECKLISTS, {
      id: templateStepId,
    });
    return axiosInstance
      .post(apiUrl, serializedData)
      .then((response) => {
        const journeyChecklist = deserialize(
          JourneyTemplateChecklist,
          response.data
        );
        setJourneyChecklistItems([...journeyChecklistItems, journeyChecklist]);
        setJourneyChecklist(journeyChecklist);
        Notification({
          message: "Success",
          description: "Journey checklist has been created successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => {
        setChecklistButtonLoading(false);
      });
  };

  const updateJourneyChecklist = (
    templateStepId?: number,
    checklistItemId?: number,
    data?: JourneyTemplateChecklist
  ) => {
    setChecklistButtonLoading(true);
    const serializedData = serialize(JourneyTemplateChecklist, data);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_STEP_CHECKLISTS, {
      id: templateStepId,
    });
    return axiosInstance
      .put(apiUrl + `/${checklistItemId}`, serializedData)
      .then((response) => {
        const journeyChecklist = deserialize(
          JourneyTemplateChecklist,
          response.data
        );
        setJourneyChecklist(journeyChecklist);
        Notification({
          message: "Success",
          description: "Checklist item has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
        return journeyChecklist
      })
      .catch((error) => { })
      .finally(() => {
        setChecklistButtonLoading(false);
      });
  };

  const updateJourneyChecklistOrder = (
    stepId?: number,
    checklistItemId?: number,
    data?: JourneyTemplateChecklist
  ) => {
    const serializedData = serialize(JourneyTemplateChecklist, data);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_STEP_CHECKLISTS_ORDER, {
      id: stepId,
      taskId: checklistItemId,
    });
    return axiosInstance
      .put(apiUrl, serializedData)
      .then((response) => {
        const journeyChecklist = deserialize(
          JourneyTemplateChecklist,
          response.data
        );
        setJourneyChecklist(journeyChecklist);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const deleteJourneyChecklist = (
    templateStepId?: number,
    checklistItemid?: number
  ) => {
    setChecklistButtonLoading(true);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_STEP_CHECKLISTS, {
      id: templateStepId,
    });
    return axiosInstance
      .delete(apiUrl + `/${checklistItemid}`)
      .then((response) => {
        Notification({
          message: "Success",
          description: "Checklist item has been deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => { })
      .finally(() => {
        setChecklistButtonLoading(false);
      });
  };

  return {
    journeyChecklist,
    journeyChecklistItems,
    checklistButtonLoading,
    fetchJourneyChecklists,
    createJourneyChecklist,
    deleteJourneyChecklist,
    updateJourneyChecklist,
    updateJourneyChecklistOrder,
    checklistLoading,
  };
};

export default JourneyChecklistService;
