import React, { FC, useEffect, useState } from "react";
import "./patients.scss";
import PatientHeader from "../../shared/components/PatientHeader";
import { Caretaker } from "../../models/Patient/patient.model";
import PatientJourneyCard from "../../shared/components/PatientJourneyCard";
import PatientService from "../../services/PatientService/patient.service";
import { useLocation, useParams } from "react-router-dom";
import JourneyService from "../../services/JourneyService/journey.service";
import { Journey } from "../../models/Journey/journey.model";
import PatientJourneyHistory from "../../shared/components/PatientJourneyHistory";

interface PatientsProps {}

const Patients: FC<PatientsProps> = (props) => {
  const {} = props;

  const { id, hospitalId } = useParams<{ hospitalId: string; id: string }>();
  const location = useLocation();
  const { patient, showPatient, patientTableLoading } = PatientService();
  const { fetchJourneys, journeyList, tableLoading } = JourneyService();

  useEffect(() => {
    if (id) {
      showPatient(hospitalId, id);
      fetchJourneys({ patient_id: id });
    }
  }, [id,location]);

  return (
    <div className="app-wrapper patients">
      <PatientHeader
        patient={patient}
        hospitalId= {hospitalId}
        dependentType={"caregiver"}
        dependents={patient?.caretaker ? [patient?.caretaker] : undefined}
        loading={patientTableLoading}
        fetchPatient = {showPatient}
      />
      <PatientJourneyHistory
        journeyList={journeyList}
        cardType={"patient"}
        loading={tableLoading}
      />
    </div>
  );
};

export default Patients;
