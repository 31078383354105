import { serializable, alias, list, object } from "serializr";
import { ArticleModel } from "../Article/article.model";
import { TagModel } from "../Tag/tag.model";

export class ArticleTagModel {
  @serializable(alias("articles", list(object(ArticleModel))))
  articles?: ArticleModel[];

  @serializable(alias("tags", list(object(TagModel))))
  tags?: TagModel[];
}
