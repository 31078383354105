import {useState} from "react";
import {JourneyTemplateAttachment} from "../../models/JourneyTemplate/journeyTemplateAttachment.model";
import axiosInstance from "../../interceptor/axiosInstance";
import {ApiRoutes} from "../../routes/routeConstants/apiRoutes";
import {deserialize, serialize} from "serializr";
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";
import {generatePath} from "react-router-dom";
import {convertJSONToFormData} from "../../shared/utils/dataFormatConverter";

const JourneyTemplateAttachmentService = () => {

    const [journeyTemplateAttachment, setJourneyTemplateAttachment] = useState<JourneyTemplateAttachment>(new JourneyTemplateAttachment());

    const [journeyTemplateAttachmentList, setJourneyTemplateAttachmentList] = useState<Array<JourneyTemplateAttachment>>([]);

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const fetchJourneyTemplateAttachments = (templateStepId?: number, attachmentType?:string) => {
        let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_ATTACHMENTS,{id:templateStepId});
        return axiosInstance
            .get(apiUrl+`?template_attachment_type=${attachmentType}`)
            .then((response) => {
                const journeyTemplateAttachments:any = deserialize(JourneyTemplateAttachment, response.data);
                setJourneyTemplateAttachmentList(journeyTemplateAttachments);
            })
            .catch((error) => {
            })
            .finally(() => {
            });
    }

    const createJourneyTemplateAttachment = (data:JourneyTemplateAttachment,templateStepId?:number) => {
        const serializedData = serialize(JourneyTemplateAttachment,data);
        const attachmentFormData = convertJSONToFormData(serializedData);
        let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_ATTACHMENTS,{id:templateStepId});
        return axiosInstance
            .post(apiUrl,attachmentFormData)
            .then((response) => {
                const journeyTemplateAttachment = deserialize(JourneyTemplateAttachment, response.data);
                setJourneyTemplateAttachmentList([...journeyTemplateAttachmentList,journeyTemplateAttachment]);
                setJourneyTemplateAttachment(journeyTemplateAttachment);
                Notification({
                    message: "Success",
                    description: "Added successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
            });
    }

    const updateJourneyTemplateAttachment = (templateStepId?: number, attachmentId?:number, data?:JourneyTemplateAttachment) => {
        setButtonLoading(true);
        const serializedData = serialize(JourneyTemplateAttachment,data);
        let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_ATTACHMENTS,{id:templateStepId});
        return axiosInstance
            .put(apiUrl+`/${attachmentId}`,serializedData)
            .then((response) => {
                const journeyTemplateAttachment = deserialize(JourneyTemplateAttachment, response.data);
                setJourneyTemplateAttachment(journeyTemplateAttachment);
                Notification({
                    message: "Success",
                    description: "Updated successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    const deleteJourneyTemplateAttachment= (templateStepId?:number, attachmentId?:number) => {
        setButtonLoading(true);
        let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_ATTACHMENTS,{id:templateStepId});
        return axiosInstance
            .delete(apiUrl+`/${attachmentId}`)
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Deleted successfully",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
            })
            .finally(() => {
                setButtonLoading(false)
            });
    }

    return{
        journeyTemplateAttachment,
        journeyTemplateAttachmentList,
        buttonLoading,
        fetchJourneyTemplateAttachments,
        createJourneyTemplateAttachment,
        deleteJourneyTemplateAttachment,
        updateJourneyTemplateAttachment
    }
}

export default JourneyTemplateAttachmentService;