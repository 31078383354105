export const onSelectScroll = (
  event: any,
  meta: any,
  currentMetaPage: number,
  totalMetaItems: number,
  fetchMeta: Function,
  hospitalId: number,
  metaPageSize: number
) => {
  let target = event.target;
  if (
    meta.length < totalMetaItems &&
    target.scrollTop + target.offsetHeight === target.scrollHeight
  ) {
    target.scrollTo(0, target.scrollHeight);
    fetchMeta(hospitalId, "", currentMetaPage + 1, metaPageSize, true);
  }
};
