import React from 'react';

function RestrictAccess() {
    return (
        <div>
            You are not authorised to view this route !!
        </div>
    );
}

export default RestrictAccess;
