export const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  USER_LOGIN: "/auth/login",
  USER_LOGOUT: "/auth/logout",
  FORGOT_PASSWORD: "/auth/forgot_password",
  RESET_PASSWORD: "/auth/password",
  CHANGE_PASSWORD: "/auth/change_password",
  //hospitals
  HOSPITALS: "/content_management/hospitals",
  HOSPITAL_LOCATIONS: "content_management/hospitals/:id/hospital_locations",
  HOSPITAL_DEPARTMENTS: "content_management/hospitals/:id/hospital_departments",
  EMPLOYEES: "content_management/employees",
  EMPLOYEES_PATIENTS: "content_management/employees/:employeeId/my_patients",
  CHECK_EMAIL: "content_management/hospitals/:hospitalId/employees_email",
  //meta endpoints
  EMPLOYEE_ROLES: "/metalists/employee_roles",
  LANGUAGES: "/metalists/languages",
  SPECIALITIES: "/metalists/specialities",
  SPECIALITIES_SPECIFIC: "/metalists/specialities/:id",
  TAGS: "/metalists/tags",
  ARTICLE_TAGS: "/metalists/article_tags",
  FAQ_TAGS: "/metalists/question_tags",
  EDIT_TAGS: "metalists/tags/:id",
  //journey template
  JOURNEY_TEMPLATES: "/content_management/journey_templates",
  JOURNEY_TEMPLATE_CATEGORIES:
    "/content_management/journey_templates/:id/journey_template_categories",
  JOURNEY_TEMPLATE_CATEGORIES_ORDER:
    "/content_management/journey_templates/:id/journey_template_categories/:templateCategoryId/order",
  JOURNEY_TEMPLATE_DUPLICATE:
    "/content_management/journey_templates/:id/duplicate",
  JOURNEY_TEMPLATE_STEPS:
    "/content_management/journey_template_categories/:id/template_steps",
  JOURNEY_TEMPLATE_STEPS_ORDER:
    "/content_management/journey_template_categories/:id/template_steps/:stepId/order",
  JOURNEY_TEMPLATE_CHECKLISTS:
    "/content_management/template_steps/:id/template_tasks",
  JOURNEY_TEMPLATE_CHECKLISTS_ORDER:
    "/content_management/template_steps/:id/template_tasks/:taskId/order",
  JOURNEY_TEMPLATE_ATTACHMENTS:
    "/content_management/template_steps/:id/template_attachments",

  //patients
  PATIENTS: "/content_management/hospitals/:hospitalId/patients",
  PATIENTS_SPECIFIC:
    "/content_management/hospitals/:hospitalId/patients/:patientId",
  //caretaker
  CARETAKERS: "/content_management/hospitals/:hospitalId/guardians",
  CARETAKERS_SPECIFIC:
    "/content_management/hospitals/:hospitalId/guardians/:guardianId",
  CHECK_CARETAKER: 'content_management/guardians/check_existing_guardian',
  EDIT_CARETAKER: 'content_management/hospitals/guardians/:id',

  //journeys
  JOURNEYS: "/journey_management/journeys",
  JOURNEY_CATEGORIES: "/journey_management/journeys/:id/journey_categories",
  JOURNEY_CATEGORIES_ORDER:
    "/journey_management/journeys/:id/journey_categories/:jounreyCategoryId/order",

  JOURNEY_STEPS: "/journey_management/journey_categories/:id/steps",
  JOURNEY_STEPS_ORDER:
    "/journey_management/journey_categories/:id/steps/:stepId/order",
  JOURNEY_ARTICLES: "/journey_management/steps/:stepId/articles",
  JOURNEY_FAQ_CATEGORIES: "/journey_management/steps/:stepId/categories",
  JOURNEY_QUESTIONS: "/journey_management/steps/:stepId/questions",
  JOURNEY_PUBLISH: "/journey_management/journeys/:id/publish",
  JOURNEY_STEP_CHECKLISTS: "/journey_management/steps/:id/tasks",
  JOURNEY_STEP_CHECKLISTS_ORDER:
    "/journey_management/steps/:id/tasks/:taskId/order",
  JOURNEY_STEP_ATTACHMENTS: "/journey_management/steps/:id/step_attachments",

  //articles
  ARTICLES: "/content_management/articles",
  ARTICLE_COMMENTS: "/content_management/articles/:id/comments",
  ARTICLE_COVER_PICTURE: "/content_management/articles/:id/cover_picture",
  //faqs
  FAQ_CATEGORIES: "/metalists/categories",
  FAQS: "/content_management/categories/:categoryId/questions",
  FAQ_SEARCH: 'content_management/questions',
  FAQS_ORDER:
    "/content_management/categories/:categoryId/questions/:questionId/order",
  //upload rich text image
  UPLOAD_IMAGE: "/content_management/contents",
  RICH_TEXT_UPLOAD_IMAGE: "/content_management/richtext",
  JOURNEY_VALIDATION: "/content_management/guardians/exists",
};
