import React, { FC } from 'react'
import "./spinnerLoader.scss"
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface SpinnerLoaderProps {

}

const SpinnerLoader: FC<SpinnerLoaderProps> = (props) => {
    const { } = props;

    return (
        <div className="spinner-loader">
            <Spin indicator={antIcon} />
        </div>
    )
}

export default SpinnerLoader;