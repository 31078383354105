import { Checkbox, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { DropdownModeEnum } from "../../../../../enums/dropdownMode.enum";
import { JourneyStepEntityEnum } from "../../../../../enums/journeyStepEntity.enum";
import {
  JourneyTemplateStep,
  JourneyTemplateStepEntityModel,
} from "../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import MetaService from "../../../../../services/MetaService/meta.service";
import DropdownField from "../../../../../shared/components/DropdownField";
import "./journeyTemplateStepEntity.scss";

interface JourneyTemplateStepEntitySelectProps {
  stepEntities?: JourneyTemplateStepEntityModel[];
  type: JourneyStepEntityEnum;
}

function JourneyTemplateStepEntity({
  stepEntities,
  type,
}: JourneyTemplateStepEntitySelectProps) {

  return (
    <div className="journey-template-step-entity">
      <div className="journey-template-step-entity__label">{type}</div>
      {stepEntities?.map((entity) => <Tag key={entity.id}>{entity.title}</Tag>)}
    </div>
  );
}

export default JourneyTemplateStepEntity;
