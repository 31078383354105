import React, { FC, useEffect, useState } from "react";
import JourneyTemplateAttachmentService from "../../../../../services/JourneyTemplateService/journeyTemplateAttachment.service";
import { JourneyTemplateAttachment } from "../../../../../models/JourneyTemplate/journeyTemplateAttachment.model";
import DeleteConfirmation from "../../../../../shared/components/DeleteConfirmation";
import { useHistory } from "react-router";
import JourneyStepAttachmentService from "../../../../../services/JourneyService/journeyStepAttachment.service";
import AttachmentViewer from "../../../../../shared/components/AttachmentViewer";
import { JourneyTemplateStep } from "../../../../../models/JourneyTemplate/journeyTemplateStep.model";
import EmptyState from "../../../../../shared/components/EmptyState";

interface JourneyTemplateAttachmentsProps {
  step?: JourneyTemplateStep;
  disableEmptyState?: boolean
  attachments: any;
  refresh: Function;
  customizeJourney?: boolean;
  showDeleteIcon?: boolean;
  showPreview?: boolean;
}

const JourneyTemplateAttachments: FC<JourneyTemplateAttachmentsProps> = (
  props
) => {
  const { step, attachments, disableEmptyState, refresh, customizeJourney, showDeleteIcon, showPreview } =
    props;

  const history = useHistory();

  const { deleteJourneyTemplateAttachment, buttonLoading } =
    JourneyTemplateAttachmentService();

  const { deleteJourneyStepAttachment, journeyAttachmentButtonLoading } =
    JourneyStepAttachmentService();

  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showAttachments, setShowAttachments] = useState<boolean>(true);
  const [currentAttachment, setCurrentAttachment] =
    useState<JourneyTemplateAttachment>(new JourneyTemplateAttachment());

  useEffect(() => {
    if (!step?.id) setShowAttachments(false);
  }, [step]);

  useEffect(() => {
    setShowAttachments(attachments.length > 0);
  }, [attachments]);

  return (
    <div className={`journey-template-step__attachments ${disableEmptyState && "no-min-width"}`}>
      {showAttachments ? (
        <>
          <DeleteConfirmation
            showConfirmation={showDelete}
            setShowConfirmation={setShowDelete}
            entityName={"attachment"}
            handleDelete={() =>
              customizeJourney
                ? deleteJourneyStepAttachment(step?.id, currentAttachment?.id)
                : deleteJourneyTemplateAttachment(
                  step?.id,
                  currentAttachment?.id
                )
            }
            buttonLoading={
              customizeJourney ? journeyAttachmentButtonLoading : buttonLoading
            }
            refresh={() => refresh(step?.id, "attachment")}
          />
          <div className="journey-template-step__label">Attachments</div>
          {[...attachments]?.map(
            (attachment: JourneyTemplateAttachment, i: number) => (
              <AttachmentViewer
                attachmentName={attachment?.attachmentName}
                attachmentUrl={attachment?.attachmentUrl}
                showDeleteIcon={showDeleteIcon}
                onDelete={() => {
                  setCurrentAttachment(attachment);
                  setShowDelete(true);
                }}
              />
            )
          )}
        </>
      )
        : disableEmptyState
          ? (showPreview? "No Files found...": "")
          : <EmptyState text="No Files found..." />}
    </div>
  );
};

export default JourneyTemplateAttachments;
