import React, { FC, useEffect, useRef, useState } from "react";
import "./caretakerList.scss";
import { useHistory } from "react-router";
import { generatePath, useParams } from "react-router-dom";
import * as appRoutes from "../../../../../routes/routeConstants/appRoutes";
import { Dropdown, Menu, Tag } from "antd";
import TableComponent from "../../../../../shared/components/TableComponent";
import {
  Caretaker,
  Patient,
} from "../../../../../models/Patient/patient.model";
import { EllipsisOutlined } from "@ant-design/icons";
import CareTakerForm from "./CaretakerForm";
import DeleteConfirmation from "../../../../../shared/components/DeleteConfirmation";
import CaretakerService from "../../../../../services/CaretakerService/caretaker.service";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { getFullName } from "../../../../../shared/utils/dataFormatConverter";

interface CaretakerListProps {
  fetchCaretakers: Function;
  tableLoading: boolean;
  caretakerList: Caretaker[];
  currentPage?: number;
  totalItems?: number;
  handlePageChange?: (page: number, pageSize?: number) => void;
  handleRowClick: Function;
}

const CaretakerList: FC<CaretakerListProps> = (props) => {
  const {
    tableLoading,
    fetchCaretakers,
    caretakerList,
    currentPage,
    totalItems,
    handlePageChange,
    handleRowClick
  } = props;

  const rootRef = useRef<null | HTMLDivElement>(null);

  const history: any = useHistory();

  const { id } = useParams<{ id: string }>();

  const { deleteCaretaker, caretakerButtonLoading } = CaretakerService();

  const [showCaretakerForm, setShowCaretakerForm] = useState<boolean>(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);

  const [currentCaretaker, setCurrentCaretaker] = useState<any>({});

  useEffect(() => {
    fetchCaretakers(id);
    if (history.location.state) {
      const { showForm } = history.location.state;
      if (showForm) {
        setShowCaretakerForm(true);
      }
    }
    rootRef!.current!.scrollIntoView({
      behavior :'smooth'
    });
  }, []);

  const columns = [
    {
      title: "NAME",
      key: "firstname",
      width: 100,
      ellipsis: true,
      render: (text: string, record: Caretaker) => (
        <>
          {/* <img src={ ?? userPlaceholder} alt=''/> */}
          <span className="text-capitalize">
            {getFullName(record)}
          </span>
        </>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      width: 150,
      ellipsis: true,
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      className: "white-nowrap",
      key: "phone",
      width: 150,
      ellipsis: true,
      render: (text: string, record: Caretaker) => formatPhoneNumberIntl((record?.isdCode ? record.isdCode : "") + text)
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      width: 70,
      render: (text: string, record: Caretaker) => (
        <Tag
          key={record?.id}
          className={`app-table-tags ${record?.isActive ? "green" : "red"}`}
        >
          {record?.isActive ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: null,
      key: "action",
      width: 50,
      render: (text: string, record: any) => (
        <Dropdown
          overlay={menu}
          placement="bottomLeft"
          trigger={["click"]}
          className="manage-groups__details--action"
          onVisibleChange={(visible) =>
            setCurrentCaretaker(visible ? record : {})
          }
        >
          <EllipsisOutlined
            className="table-menu"
            onClick={(e) => e.stopPropagation()}
          />
        </Dropdown>
      ),
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          setShowCaretakerForm(true);
        }}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          setShowDeleteConfirmation(true);
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const deleteHandler = async () => {
    if (!id || !currentCaretaker.id) return;
    await deleteCaretaker(id, currentCaretaker.id);
  };

  return (
    <div className="staffs patients-list" ref = {rootRef}>
      <TableComponent
        loading={tableLoading}
        data={caretakerList}
        columns={columns}
        onPageChange={handlePageChange}
        currentPage={currentPage}
        total={totalItems}
        handleRowClick={(record: Patient) => handleRowClick(record, "caretaker") }
      />

      <CareTakerForm
        showForm={showCaretakerForm}
        setShowForm={setShowCaretakerForm}
        caretaker={currentCaretaker}
        hospitalId={id}
        refreshList={fetchCaretakers}
      />

      <DeleteConfirmation
        showConfirmation={showDeleteConfirmation}
        setShowConfirmation={setShowDeleteConfirmation}
        entityName={"caregiver"}
        handleDelete={deleteHandler}
        buttonLoading={caretakerButtonLoading}
        refresh={fetchCaretakers}
      />
    </div>
  );
};

export default CaretakerList;
