import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getFileType } from "../../utils/dataFormatConverter";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "./attachmentViewer.scss";

interface AttachmentViewerProps {
  attachmentUrl?: string;
  attachmentName?: string;
  showDeleteIcon?: boolean;
  onDelete?: () => void;
}

function AttachmentViewer({
  attachmentUrl,
  attachmentName,
  showDeleteIcon,
  onDelete,
}: AttachmentViewerProps) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const history = useHistory();

  const [showAttachment, setShowAttachment] = useState(false);

  const [numPages, setNumPages] = useState(null);

  const [pdfUrl, setPdfUrl] = useState<string | ArrayBuffer | null>();

  const [pageNumber, setPageNumber] = useState(1);

  const handleShowModal = () => setShowAttachment(true);

  const handleCloseModal = () => setShowAttachment(false);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  return (
    <div className="attachment-viewer">
      <div className="attachment-viewer__label" onClick={handleShowModal}>
        {attachmentName}
      </div>
      {history?.location?.state !== "" && showDeleteIcon && (
        <div className="remove-icon" onClick={onDelete}>
          x
        </div>
      )}
      <Modal
        title={null}
        footer={null}
        width="60%"
        visible={showAttachment}
        maskClosable={false}
        onCancel={handleCloseModal}
        className="attachment-viewer-modal"
      >
        {attachmentName && getFileType(attachmentName) === "image" ? (
          <img src={attachmentUrl} alt="Attachment" />
        ) : (
          <iframe src={attachmentUrl} />
        )}
      </Modal>
    </div>
  );
}

export default AttachmentViewer;
