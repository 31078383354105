import { useState } from "react";
import { Journey } from "../../models/Journey/journey.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationModel } from "../../models/pagination.model";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";

const JourneyService = () => {
  const history = useHistory();

  const [journey, setJourney] = useState<Journey>();

  const [journeyList, setJourneyList] = useState<Array<Journey>>([]);

  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [totalItems, setTotalItems] = useState<number>(0);

  const fetchJourneys = (
    queryParams: any = {},
    page?: number,
    pageSize?: number
  ) => {
    setTableLoading(true);
    let apiUrl =
      ApiRoutes.JOURNEYS +
      `?${
        Object.keys(queryParams).length
          ? `${Object.keys(queryParams)
              .map((o) => o + "=" + queryParams[o])
              .join("&")}&`
          : ""
      }${page ? `page=${page}&page_size=${pageSize}` : ""}`;
    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const journeys: any = deserialize(Journey, response.data["results"]);
        const paginationData = deserialize(PaginationModel, response.data);
        setJourneyList(journeys);
        setCurrentPage(paginationData?.page);
        setTotalItems(paginationData?.total);
      })
      .catch((error) => {})
      .finally(() => {
        setTableLoading(false);
      });
  };

  const createJourney = (data: Journey) => {
    setButtonLoading(true);
    const journeyData = serialize(Journey, data);
    return axiosInstance
      .post(ApiRoutes.JOURNEYS, journeyData)
      .then((response) => {
        const journey = deserialize(Journey, response.data);
        setJourneyList([...journeyList, journey]);
        history.push(
          generatePath(appRoutes.JOURNEY_FORM, { id: journey?.id }),
          2
        );
        Notification({
          message: "Success",
          description: "Journey has been created successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => {})
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const showJourney = (id?: string) => {
    setTableLoading(true);
    return axiosInstance
      .get(ApiRoutes.JOURNEYS + `/${id}`)
      .then((response) => {
        const journey = deserialize(Journey, response.data);
        setJourney(journey);
        return journey;
      })
      .catch((error) => {})
      .finally(() => {
        setTableLoading(false);
      });
  };

  const updateJourney = (data: Journey, id?: string) => {
    setButtonLoading(true);
    const journeyData = serialize(Journey, data);
    return axiosInstance
      .put(ApiRoutes.JOURNEYS + `/${id}`, journeyData)
      .then(() => {
        Notification({
          message: "Success",
          description: "Journey has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => {})
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const deleteJourney = (id?: number) => {
    setButtonLoading(true);
    return axiosInstance
      .delete(ApiRoutes.JOURNEYS + `/${id}`)
      .then((response) => {
        Notification({
          message: "Success",
          description: "Journey has been deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => {})
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const publishJourney = (id: string | number) => {
    setButtonLoading(true);
    return axiosInstance
      .put(generatePath(ApiRoutes.JOURNEY_PUBLISH, { id }))
      .then((response) => {
        Notification({
          message: "Success",
          description: "Journey has been published successfully",
          type: NotificationTypes.SUCCESS,
        });
        return response;
      })
      .catch((error) => {})
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return {
    journey,
    journeyList,
    tableLoading,
    buttonLoading,
    fetchJourneys,
    createJourney,
    showJourney,
    updateJourney,
    deleteJourney,
    currentPage,
    publishJourney,
    totalItems,
  };
};

export default JourneyService;
