import React, { FC, useEffect, useState } from "react";
import AppHeader from "../../shared/components/AppHeader";
import { Tag } from 'antd';
import './styles.scss'
import { DeleteOutlined, EditOutlined, TagOutlined } from '@ant-design/icons';
import TagForm from "./TagForm";
import TagService from "../../services/TagService/tags.service";
import { TagModel } from "../../models/Tag/tag.model";
import DeleteConfirmation from "../../shared/components/DeleteConfirmation";
import ButtonComponent from "../../shared/components/ButtonComponent";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

interface SupportProps { }

const { CheckableTag } = Tag;

const Tags: FC<SupportProps> = (props) => {

  const [editTag, setEditTag] = useState<any>();
  const [tagForm, setTagForm] = useState<boolean>(false);
  const [deleteForm, setDeleteForm] = useState<boolean>(false);
  const [checkedList, setCheckedList] = useState<TagModel[]>([]);
  const {tags, fetchTags, deleteTag, tagButtonLoading} = TagService();
  const [sortedTags, setSortedTags] = useState<TagModel[]>([])
  const [searchText,setSearchText] = useState<string>('');

  useEffect(() => {
    fetchTags();
    setCheckedList([])
  },[])

  useEffect(() => {
    setSortedTags(sortedList(tags));
  },[tags])

  const sortedList = (arr:TagModel[]) => arr.sort((a, b) =>
  (a.name as string).localeCompare(b.name as string));


  const handleTagSearch = (searchText: string) => {
    fetchTags({search:searchText});
  };

  const handleChange = (tag: TagModel, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...checkedList, tag]
      : checkedList.filter((t) => t.id !== tag.id);
    setCheckedList(nextSelectedTags);
  };


  return (
    <div className="app-wrapper tags">
      <AppHeader
        iconClassName="anticon anticon-tag"
        title="Tags"
        isSearchEnabled={true}
        handleSearch={handleTagSearch}
        searchValue={searchText}
        setSearchValue={setSearchText}
        showGoBack={false}
        buttonText="Tag"
        buttonHandler={() => setTagForm(true)}
      />
   <div className="tag-header">
      <ButtonComponent 
        text={'Delete'}
        className="tag-header__button"
        key={"header-button"}
        type="primary"
        disabled={(checkedList.length === 0)}
        onClick={() => setDeleteForm(true)}
  />
    </div>
      <div className="tagManager">

        {sortedTags?.map((tag: TagModel) => (
          <div className="tag-container text-capitalize" >
             <CheckableTag 
            key={tag.id}
            checked={checkedList.includes(tag)}
            onChange={(checked) => handleChange(tag, checked)}
          >
            {tag.name}
            <span className="tag-actions">
                <EditOutlined
                 
                  onClick={() => {
                    setEditTag(tag);
                    setTagForm(true);
                  }}
                />
                
             </span> 
          </CheckableTag>

          </div>
        ))}

        <TagForm
          setShowForm={setTagForm}
          showForm={tagForm}
          tag={editTag}
          afterCloseHandler={() => {
            setEditTag({})
            setCheckedList([])}}
          refreshList={fetchTags}
        />
        <DeleteConfirmation
          handleDelete={async () => {
            for (let i in checkedList){
              await deleteTag(checkedList[i].id as number)
            }
            setCheckedList([]);
            Notification({
              message: "Success",
              description: "Selected tags deleted successfully",
              type: NotificationTypes.SUCCESS,
          });
            }}
          setShowConfirmation={setDeleteForm}
          showConfirmation={deleteForm}
          entityName={'Tag'}
          buttonLoading={tagButtonLoading}
          key="delete-confirmation"
          refresh={() => fetchTags({ search: searchText })}
        />
      </div>
    </div>
  );
};

export default Tags;
