import { useState } from "react"
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { TagModel } from "../../models/Tag/tag.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

const TagService = () => {
  const [tag,setTag] = useState();
  const [tags,setTags] = useState<TagModel[]>([]);
  const [tagButtonLoading,setTagButtonLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const fetchTags = (params: any = {}) => {
    setTagButtonLoading(true);
    return axiosInstance
      .get(ApiRoutes.TAGS, { params: params })
      .then((response) => {
        const tags: any = deserialize(TagModel, response.data["tags"]);
        setTags(tags);
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setTagButtonLoading(false)
      });
  };
  const createTag = (data: TagModel) => {
    setTagButtonLoading(true);
    const values = serialize(TagModel,data);
    return axiosInstance
      .post(ApiRoutes.TAGS,values)
        .then((response) => {
          Notification({
            message: "Success",
            description: "Tag created successfully",
            type: NotificationTypes.SUCCESS,
        });
          return response
        })
        .catch((error) => {
          setError(error)
        })
        .finally(() => {
          setTagButtonLoading(false)
        });
  }

  const updateTag = (data: TagModel,id: number) => {
    setTagButtonLoading(true);
    const values = serialize(TagModel,data);
    return axiosInstance
      .put(generatePath(ApiRoutes.EDIT_TAGS,{id}),values)
        .then((response) => {
          Notification({
            message: "Success",
            description: "Tag edited successfully",
            type: NotificationTypes.SUCCESS,
        });
          return response
        })
        .catch((error) => {
          setError(error)
        })
        .finally(() => {
          setTagButtonLoading(false)
        });
  }

  const deleteTag = (id:number) =>  {
    setTagButtonLoading(true);
    return axiosInstance
      .delete(generatePath(ApiRoutes.EDIT_TAGS,{id}))
        .then(() => {
        return true
        })
        .catch((error) => {
          setError(error)
        })
        .finally(() => {
          setTagButtonLoading(false)
        });

  } 
  return{
    tagButtonLoading,
    tag,
    createTag,
    fetchTags,
    updateTag,
    deleteTag,
    tags
  }
}

export default TagService
