import { MetaModel } from "./../meta.model";
import { serializable, alias, primitive, list, object } from "serializr";
import { Staff } from "../Hospital/staff.model";

export class JourneyTemplate {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("id", primitive()))
  key?: number;

  @serializable(alias("tag_ids", list(primitive())))
  tagIds?: number[];

  @serializable(alias("tags", list(object(MetaModel))))
  tags?: MetaModel[];

  @serializable(alias("name", primitive()))
  name: string = "";

  @serializable(alias("no_of_weeks", primitive()))
  noOfWeeks: number = 0;

  @serializable(alias("step_count", primitive()))
  noOfSteps?: number = 0;

  @serializable(alias("status", primitive()))
  status?: boolean;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;
}
