import moment from "moment";
import React from "react";

export const getYearsAndMonths = (dateString: string) => {
  const date = moment(dateString, "YYYY-MM-DD");
  const age = moment.duration(moment().diff(date));
  return <span> {age.years() + " years " + age.months() + " months"}</span>;
};

export const getAgeText = (dateString: string, small?: boolean) => {
  const date = moment(dateString, "YYYY-MM-DD");
  const age = moment.duration(moment().diff(date));
  const days = age.days();
  const months = age.months();
  const years = age.years();
  let result = "";
  if (years <= 1) {
    if (years === 0) {
      if ( months === 0 && days) {
        result = small? `${days}d` :`${days} Days`;
      }
      else if ( months === 1 && days) {
        result = small? `${months}m ${days}d` :`${months} Month ${days} Days`;
      }
      else if (months >= 2 && months <= 6) {
        result = small? `${months}m ${days}d` : `${months} Months ${days} Days`;
      } else {
        result = small? `${months}m` : `${months} Months`;
      }
    
    } else {
      if(months === 0 ){
        result = small? `${years}y` :`${years} Y`;
      }
      else if ( months === 1 && years) {
        result = small? `${years}y ${months}m` :`${years} Y ${months} Month`;

      }
      else{
        result = small? `${years}y - ${months}m` :  `${years} Y ${months} Months`;
      }
    }
  } 
  else if (years > 1 && years < 6) {
    if ( months === 0) {
      result = small? `${years}y` :`${years} Y`;
    }
    else if( months === 1 ){
      result = small?`${years}y ${months}m` :`${years} Y ${months} Month`;
    }
      else{
      result = small? `${years}y ${months}m` :`${years} Y ${months} Months`;
    }
  } else {
    result = small? `${years}y` : `${years} Y`;
  }
  return <span>{result}</span>;
};
