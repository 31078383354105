import { serializable, alias, list, object } from "serializr";
import { FaqModel } from "../Faq/faq.model";
import { TagModel } from "../Tag/tag.model";

export class FaqTagModel {
  @serializable(alias("questions", list(object(FaqModel))))
  questions?: FaqModel[];

  @serializable(alias("tags", list(object(TagModel))))
  tags?: TagModel[];
}
