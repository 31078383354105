import { serializable, alias, primitive, list, object } from "serializr";
import { JourneyTemplateStep } from "./journeyTemplateStep.model";

export class JourneyTemplateCategory {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("order", primitive()))
  order?: string;

  @serializable(alias("template_step_list", list(object(JourneyTemplateStep))))
  templateStepList?: JourneyTemplateStep[] = [];

  @serializable(alias("step_list", list(object(JourneyTemplateStep))))
  stepList?: JourneyTemplateStep[] = [];
}
