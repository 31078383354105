import React, { FC, useRef, useState } from "react";
import "./richTextViewer.scss";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "ckeditor5-build-classic-plus";
import { Editor } from '@tinymce/tinymce-react';

interface RichTextViewerProps {
  content?: string;
  borderless?: boolean
  height?: string | number
}

const RichTextViewer: FC<RichTextViewerProps> = (props) => {
  const { content, height, borderless } = props;
  const editorRef = useRef<any>(null);

  return (
    <div className={`rich-text-viewer ${borderless && "borderless"}`}>
      <Editor
        apiKey='hn69kd77i4pbxercu646hirzqfr26gyhr7fn2wf6gwvhschf'
        onInit={(evt, editor) => editorRef.current = editor}
        value={content || ""}
        init={{
          toolbar: [""],
          height: height || "350px",
          isReadOnly: true,
          menubar: false,
          content_filter: function (content: any, format:any) {
            // Remove all <br> tags from the content
            return content.replace(/<br\s*\/?>/gi, '');
          },
          link: {
            addTargetToExternalLinks: true,
          },
        }}
        disabled
      />
    </div>
  );
};

export default RichTextViewer;
