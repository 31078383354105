import React, { FC, useEffect, useRef, useState } from "react";
import "./articleComments.scss";
import { Divider, Input } from "antd";
import userPlaceholder from "../../../../assets/userPlaceholder.png";
import moment from "moment";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import { useParams } from "react-router-dom";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthState } from "../../../../store/reducers/authReducer";
import ArticleCommentService from "../../../../services/ArticleService/articleComment.service";
import { ArticleCommentModel } from "../../../../models/Article/article.model";
import useInfiniteScroll from "react-infinite-scroll-hook";
import SkeletonLoader from "../../../../shared/components/SkeletonLoader";
import DpChip from "../../../../shared/components/DpChip";

const { TextArea } = Input;

interface ArticleCommentsProps extends AuthState { }

const ArticleComments: FC<ArticleCommentsProps> = (props) => {
  const { user } = props;

  const { id } = useParams();

  const commentsEndRef = useRef<any>(null);

  const scrollToBottom = () => {
    if (commentsEndRef?.current)
      commentsEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const {
    fetchArticleComments,
    commentList,
    tableLoading,
    createArticleComment,
    buttonLoading,
    updateArticleComment,
    currentPage,
    totalItems,
  } = ArticleCommentService();

  const [currentComment, setCurrentComment] = useState<ArticleCommentModel>();
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [userComment, setUserComment] = useState<string | undefined>();
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  useEffect(() => {
    if (id) fetchArticleComments(id);
  }, [id]);

  useEffect(() => {
    setHasNextPage(currentPage * 10 < totalItems);
  }, [currentPage, totalItems]);

  const handleCommentCreate = () => {
    if (id)
      createArticleComment(id, { content: userComment }).then(() => {
        handleSuccess();
        scrollToBottom();
      });
  };

  const handleEditComment = () => {
    if (id)
      updateArticleComment(
        { content: userComment },
        id,
        currentComment?.id
      ).then(() => {
        handleSuccess();
      });
  };

  const handleSuccess = () => {
    setUserComment(undefined);
    setShowEdit(false);
    setCurrentComment({});
  };

  const loadMore = () => {
    if (id) fetchArticleComments(id, { page: currentPage + 1 });
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: tableLoading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: false,
    rootMargin: "0px 0px 20px 0px",
  });

  return (
    <div className="article-comments">
      <div className={"article-comments__title"}>
        All Comments({totalItems})
      </div>
      <Divider />
      <div className={"article-comments__list"} ref={rootRef}>
        {commentList.map((comment, i) => (
          <div className={"article-comment"} key={i}>
            <div className={"article-comment__header"}>
              <div className={"article-comment__user-image"}>
                {/* <img
                  src={ ?? userPlaceholder}
                  alt=""
                /> */}
                <DpChip name={comment?.createdBy?.name || ""}
                  url={comment.createdBy?.displayPictureUrl}
                />
              </div>
              <div className={"article-comment__user-name"}>
                {comment?.createdBy?.name}
              </div>
              <div className={"article-comment__time"}>
                {moment(comment?.createdAt).format("MMMM DD,YYYY")} at{" "}
                {moment(comment?.createdAt).format("HH:mma")}
              </div>
              {user?.id === comment?.createdBy?.id && !showEdit && (
                <div className={"article-comment__time float-right"}>
                  <span
                    className={"cursor-pointer"}
                    onClick={() => {
                      setShowEdit(true);
                      setCurrentComment(comment);
                      setUserComment(undefined);
                    }}
                  >
                    <i className={"icon-edit"} />
                  </span>
                </div>
              )}
            </div>
            <div className={"article-comment__content"}>
              {showEdit && currentComment?.id === comment?.id ? (
                <div className={"article-comment__new-comment"}>
                  <div className={"article-comment__input"}>
                    <TextArea
                      rows={
                        currentComment?.content &&
                          currentComment?.content?.length > 120
                          ? currentComment?.content?.length / 60
                          : 2
                      }
                      placeholder={"What are your thoughts?"}
                      value={userComment ?? currentComment?.content}
                      onChange={(e) => setUserComment(e.target.value)}
                    />
                    <ButtonComponent
                      type={"text"}
                      onClick={() => {
                        handleSuccess();
                      }}
                      text={"Cancel"}
                      className={"article-comment__input__cancel"}
                    />
                    <ButtonComponent
                      type={"primary"}
                      onClick={handleEditComment}
                      text={"Update"}
                      disabled={!userComment}
                      loading={buttonLoading}
                    />
                  </div>
                </div>
              ) : (
                comment?.content
              )}
            </div>
          </div>
        ))}
        <div ref={commentsEndRef} />
        {(tableLoading || hasNextPage) &&
          [...Array(2)].map((x) => (
            <div ref={sentryRef} className={"chat__list__head"}>
              <SkeletonLoader avatar={true} rows={1} />
            </div>
          ))}
      </div>
      <div className={"article-comment__new-comment"}>
        <div className={"article-comment__current-user-image"}>
          <img src={userPlaceholder} alt={""} />
        </div>
        <div className={"article-comment__input"}>
          <TextArea
            rows={6}
            placeholder={"What are your thoughts?"}
            value={showEdit ? "" : userComment}
            onChange={(e) => setUserComment(e.target.value)}
            disabled={showEdit}
          />
          {!showEdit && (
            <ButtonComponent
              type={"primary"}
              onClick={handleCommentCreate}
              text={"Post"}
              disabled={!userComment}
              loading={buttonLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthContainer(ArticleComments);
