import React, { FC, useEffect, useState } from "react";
import "./departmentForm.scss";
import { Col, Modal, Row } from "antd";
import { Form, Formik } from "formik";
import { departmentValidationSchema } from "./departmentFormValidation";
import InputField from "../../../../../shared/components/InputField";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import { Department } from "../../../../../models/Hospital/department.model";
import DropdownField from "../../../../../shared/components/DropdownField";
import { DropdownModeEnum } from "../../../../../enums/dropdownMode.enum";
import DepartmentService from "../../../../../services/HospitalService/department.service";
import MetaService from "../../../../../services/MetaService/meta.service";
import { generatePath, useParams } from "react-router-dom";
import { ApiRoutes } from "../../../../../routes/routeConstants/apiRoutes";
import { MetaModel } from "../../../../../models/meta.model";
import LocationService from "../../../../../services/HospitalService/location.service";
import { onSelectScroll } from "../../../../../shared/utils/dropdownLazyLoader";
import PhoneNumberInput from "../../../../../shared/components/PhoneNumberInput";
import RichTextField from "../../../../../shared/components/RichTextField";

interface DepartmentFormProps {
  showForm: boolean;
  setShowForm: (showForm: boolean) => void;
  department: Department;
  refreshList?: Function;
  refreshDepartment?: Function;
}

const DepartmentForm: FC<DepartmentFormProps> = (props) => {
  const { showForm, setShowForm, department, refreshList, refreshDepartment } =
    props;

  const { id } = useParams();

  const { buttonLoading, createDepartment, updateDepartment } =
    DepartmentService();

  const {
    fetchLocations,
  } = LocationService();

  const metaPageSize = 12;

  const [initialValue, setInitialValue] = useState<Department>(new Department());

  useEffect(() => {
    fetchLocations(id, "", 1, metaPageSize, true);
  }, []);

  useEffect(() => {
    setInitialValue({ ...department });
  }, [department]);

  const onSubmit = async (
    values: Department,
    formProps: { resetForm: () => void }
  ) => {
    const response = department?.id
      ? await updateDepartment(id, values, department.id)
      : await createDepartment(id, values)
    if (!response) return
    setShowForm(false);
    formProps.resetForm();
    if (refreshList) refreshList();
    if (refreshDepartment) refreshDepartment(id, department.id);
  };

  return (
    <Modal
      title={null}
      visible={showForm}
      onCancel={() => setShowForm(false)}
      footer={null}
      destroyOnClose
      maskClosable = {false}
      className="hospital-form department-form"
    >
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={departmentValidationSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <div className="hospital-form__header">
              {Object.keys(department).length ? "Edit" : "New"} Department
            </div>

            <div className="hospital-form__row">
              <div className="hospital-form__field">
                <div className="form-label">Department Name</div>
                <InputField
                  type="text"
                  name="name"
                  placeholder="Enter department name"
                />
              </div>
              <div className="hospital-form__field">
                <div className="form-label">Email</div>
                <InputField
                  type="email"
                  name="email"
                  placeholder="Enter email"
                />
              </div>
            </div>

            <div className="hospital-form__row">
              <div className="hospital-form__field">
                <div className="form-label">Phone</div>
                <Row>
                  <Col span={18}>
                    <PhoneNumberInput
                      name={"phone"}
                      value={values.phone}
                      onChange={(value) => {
                        setFieldValue("phone", value);
                      }}
                    />
                  </Col>
                  <Col span={6}>
                    <InputField
                      type="number"
                      name="extCode"
                      placeholder="XXXXX"
                    />
                  </Col>
                </Row>
              </div>
              <div className="hospital-form__field department-form__field">
                <div className="form-label">Location</div>
                <InputField
                  type="text"
                  name="location"
                  placeholder="Enter Location"
                />
              </div>
            </div>

            <div className="hospital-form__row">
              <div className="hospital-form__field textarea">
                <div className="form-label">Description</div>
                <RichTextField
                  name={"description"}
                  editorData={values.description || ""}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>

            <div className="hospital-form__actions">
              <ButtonComponent
                className="hospital-form__action"
                type="text"
                text="Cancel"
                onClick={() => setShowForm(false)}
              />
              <ButtonComponent
                htmlType="submit"
                loading={buttonLoading}
                className="hospital-form__action"
                type="primary"
                text={Object.keys(department).length ? "Update" : "Save"}
                disabled={!isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DepartmentForm;
