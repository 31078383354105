import React, { FC, useEffect, useState } from "react";
import "./departments.scss";
import AppHeader from "../../../../shared/components/AppHeader";
import { Dropdown, Menu, Modal, Pagination } from "antd";
import { Department } from "../../../../models/Hospital/department.model";
import { EllipsisOutlined } from "@ant-design/icons";
import avatarPlaceholder from "../../../../assets/userPlaceholder.png";
import DepartmentForm from "./DepartmentForm";
import DeleteConfirmation from "../../../../shared/components/DeleteConfirmation";
import DepartmentDetails from "./DepartmentDetails";
import DepartmentService from "../../../../services/HospitalService/department.service";
import { generatePath, useHistory, useLocation, useParams } from "react-router-dom";
import SkeletonLoader from "../../../../shared/components/SkeletonLoader";
import EmptyState from "../../../../shared/components/EmptyState";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
interface DepartmentsProps { }

const Departments: FC<DepartmentsProps> = (props) => {
  const { } = props;

  const { id } = useParams();

  const history = useHistory()

  const location = useLocation()

  const {
    departmentList,
    fetchDepartments,
    deleteDepartment,
    buttonLoading,
    tableLoading,
    currentDepartmentPage,
    totalDepartmentItems,
  } = DepartmentService();

  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [currentDepartment, setCurrentDepartment] = useState<Department>({});
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const departmentPageSize = 12;

  useEffect(() => {
    fetchDepartments(id, "", 1, departmentPageSize);
  }, []);

  useEffect(() => {
    setShowForm(!!(location as any)?.state?.showForm)
  }, [location.state])

  const handleDepartmentSearch = (searchText: string) => {
    setSearchText(searchText);
    fetchDepartments(id, searchText);
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    fetchDepartments(id, searchText, page, pageSize);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setShowForm(true)}>
        Edit
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setShowDeleteConfirmation(true);
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="app-wrapper departments">
      <AppHeader
        title={"Departments"}
        buttonText={"Department"}
        isSearchEnabled={true}
        buttonHandler={() => {
          setCurrentDepartment({});
          setShowForm(true);
        }}
        searchValue={searchText}
        setSearchValue={setSearchText}
        handleSearch={handleDepartmentSearch}
        showGoBack={true}
      />

      <DepartmentDetails
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        departmentId={currentDepartment?.id}
      />

      <DepartmentForm
        showForm={showForm}
        setShowForm={setShowForm}
        department={currentDepartment}
        refreshList={() => {
          fetchDepartments(
            id,
            searchText,
            currentDepartmentPage,
            departmentPageSize
          );
          (location as any)?.state?.showForm && Modal.confirm({
            title: null,
            icon: null,
            width: "40%",
            className: "ant-confirm-modal",
            content: (
              <div style={{ fontWeight: "normal", fontSize: "1.2rem" }}>
                Department has been created successfully. Do you wish to create a
                staff?
              </div>
            ),
            okText: "Continue",
            cancelText: "Cancel",
            onOk: () => {
              setShowForm(false);
              history.push({
                pathname: generatePath(AppRoutes.SHOW_HOSPITAL, { id }),
                state: {
                  showStaffForm: true,
                },
              });
            },
            onCancel: () => { }
          })
        }}
      />

      <DeleteConfirmation
        showConfirmation={showDeleteConfirmation}
        setShowConfirmation={setShowDeleteConfirmation}
        entityName={"Department"}
        handleDelete={() => deleteDepartment(id, currentDepartment?.id)}
        buttonLoading={buttonLoading}
        refresh={() => fetchDepartments(id)}
      />

      {!tableLoading &&
        !departmentList?.length
        ? <EmptyState />
        : departmentList?.map((department: Department, i: number) => (
          <div
            className="manage-groups__details-card"
            key={i}
            onClick={() => {
              setCurrentDepartment(department);
              setShowDetails(true);
            }}
          >
            <div onClick={(event) => event?.stopPropagation()}>
              <Dropdown
                overlay={menu}
                placement="bottomCenter"
                trigger={["click"]}
                className="manage-groups__details--action"
                onVisibleChange={(visible) => {
                  setCurrentDepartment(visible ? department : new Department());
                }}
              >
                <EllipsisOutlined />
              </Dropdown>
            </div>
            <div>
              <div className="manage-groups__details-card--name text-capitalize">
                {department.name}
              </div>
              <div className="manage-groups__details-card--location text-capitalize">
                <span className="icon-location" /> {department?.location ?? "-"}
              </div>
            </div>
          </div>
        ))}
      {!tableLoading && departmentList && departmentList?.length > 0 && (
        <div className="departments__pagination">
          <Pagination
            onChange={handlePageChange}
            current={currentDepartmentPage}
            total={totalDepartmentItems}
            hideOnSinglePage={true}
            pageSize={departmentPageSize}
          />
        </div>
      )}
      {tableLoading &&
        [...Array(6)].map((x, i) => (
          <div className="manage-groups__details-card" key={i}>
            <SkeletonLoader rows={1} />
          </div>
        ))}
    </div>
  );
};

export default Departments;
