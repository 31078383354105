import { generatePath } from 'react-router-dom';
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationModel } from "../../models/pagination.model";
import { Patient } from "../../models/Patient/patient.model";

const PatientService = () => {

    const [patient, setPatient] = useState<Patient>({});

    const [patientList, setPatientList] = useState<Array<Patient>>([]);

    const [patientTableLoading, setPatientTableLoading] = useState<boolean>(false);

    const [patientButtonLoading, setPatientButtonLoading] = useState<boolean>(false);

    const [patientCurrentPage, setPatientCurrentPage] = useState<number>(1);

    const [patientTotalItems, setPatientTotalItems] = useState<number>(0);

    const fetchPatients = (hospitalId: string | number, queryParams: any = {}, page?: number, pageSize?: number, isMeta?: boolean) => {
        setPatientTableLoading(true);
        let apiUrl = generatePath(ApiRoutes.PATIENTS, { hospitalId }) +
            `?${Object.keys(queryParams).length ? `${Object.keys(queryParams).map(o => o + "=" + queryParams[o]).join('&')}&` : ''}${page ? `page=${page}&page_size=${pageSize}` : ''}`;
        return axiosInstance
            .get(apiUrl)
            .then((response) => {
                const patients: any = deserialize(Patient, isMeta? response.data: response.data['results']);
                const paginationData = deserialize(PaginationModel, response.data);
                setPatientList(patients);
                setPatientCurrentPage(paginationData?.page);
                setPatientTotalItems(paginationData?.total);
            })
            .catch((error) => {
            })
            .finally(() => {
                setPatientTableLoading(false)
            });
    }

    const showPatient = (hospitalId: string | number, id?: string) => {
        setPatientTableLoading(true)
        return axiosInstance
            .get(generatePath(ApiRoutes.PATIENTS, { hospitalId }) + `/${id}`)
            .then((response) => {
                const patient: Patient = deserialize(Patient, response.data);
                setPatient(patient)
            })
            .catch((error) => {
            })
            .finally(() => {
                setPatientTableLoading(false)
            });
    }

    const createPatient = (hospitalId: string | number, data: Patient) => {
        setPatientButtonLoading(true);
        const caretakerData = serialize(Patient, data);
        return axiosInstance
            .post(generatePath(ApiRoutes.PATIENTS, { hospitalId }), caretakerData)
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Patient has been created successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return response.data
            })
            .catch((error) => {
                throw error
            })
            .finally(() => {
                setPatientButtonLoading(false)
            });
    }

    const updatePatient = (hospitalId: string | number, data: Patient) => {
        setPatientButtonLoading(true);
        const caretakerData = serialize(Patient, data);
        return axiosInstance
            .put(generatePath(ApiRoutes.PATIENTS_SPECIFIC, { hospitalId, patientId: data.id }), caretakerData)
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Patient has been updated successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return response.data
            })
            .catch((error) => {
                throw error
            })
            .finally(() => {
                setPatientButtonLoading(false)
            });
    }

    const deletePatient = (hospitalId: string | number, patientId: string | number) => {
        setPatientButtonLoading(true);
        return axiosInstance
            .delete(generatePath(ApiRoutes.PATIENTS_SPECIFIC, { hospitalId, patientId }))
            .then((response) => {
                Notification({
                    message: "Success",
                    description: "Patient has been deleted successfully",
                    type: NotificationTypes.SUCCESS,
                });
                return true
            })
            .catch((error) => {
                throw error
            })
            .finally(() => {
                setPatientButtonLoading(false)
            });
    }

    const fetchStaffPatients = async (employeeId: number | string, queryParams: any = {}, page?: number, pageSize?: number) => {
        setPatientTableLoading(true)
        try {
            const API_URL = generatePath(ApiRoutes.EMPLOYEES_PATIENTS, { employeeId }) +
                `?${Object.keys(queryParams).length ? `${Object.keys(queryParams).map(o => o + "=" + queryParams[o]).join('&')}&` : ''}${page ? `page=${page}&page_size=${pageSize}` : ''}`;
            const response = await axiosInstance.get(API_URL)
            const patients: any = deserialize(Patient, response.data['results']);
            const paginationData = deserialize(PaginationModel, response.data);
            setPatientList(patients);
            setPatientCurrentPage(paginationData?.page);
            setPatientTotalItems(paginationData?.total);
        } catch (error) {

        } finally {
            setPatientTableLoading(false)
        }
    }

    return {
        patientList,
        patient,
        patientTableLoading,
        patientButtonLoading,
        fetchStaffPatients,
        fetchPatients,
        showPatient,
        createPatient,
        updatePatient,
        deletePatient,
        patientCurrentPage,
        patientTotalItems
    }
}

export default PatientService;