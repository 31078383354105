import React, { FC } from "react";
import "./support.scss";
import { generatePath } from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import AppHeader from "../../shared/components/AppHeader";
import { Journey } from "../../models/Journey/journey.model";
import TableComponent from "../../shared/components/TableComponent";
import userPlaceholder from "../../assets/userPlaceholder.png";
import moment from "moment";
import { Dropdown, Tag } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

interface SupportProps { }

const Support: FC<SupportProps> = (props) => {
  const { } = props;

  const columns = [
    {
      title: "HOSPITAL NAME",
      dataIndex: "hospitalName",
      key: "hospitalName",
      render: (text: string, record: Journey) => <>{record?.hospital?.name}</>,
    },
    {
      title: "ISSUE TYPE",
      dataIndex: "issueType",
      key: "issueType",
    },
    {
      title: "PATIENT NAME",
      dataIndex: "patientName",
      key: "patientName",
      render: (text: string, record: Journey) => (
        <>
          {/* <img
            src={ ?? userPlaceholder}
            alt=""
            className="patient-table-image"
          /> */}
          <span>{record?.patient?.fullname}</span>
        </>
      ),
    },
    {
      title: "RAISED DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, record: Journey) => (
        <>{moment(record?.createdAt).format("DD/MM/YYYY HH:mm a")}</>
      ),
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      render: (text: string, record: Journey) => (
        <Tag key={record?.id} className={`app-table-tags ${record?.status}`}>
          {record?.status}
        </Tag>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      hospital: {
        name: "Mcgill Healthcare",
      },
      issueType: "Billing",
      patient: {
        fullname: "Nick wood",
      },
      createdAt: moment("2021-11-10 17:05:56"),
      status: "resolved",
    },
    {
      id: 2,
      hospital: {
        name: "Mcgill Healthcare",
      },
      issueType: "Scan",
      patient: {
        fullname: "Aaron fox",
      },
      createdAt: moment("2021-11-10 19:20:03"),
      status: "pending",
    },
  ];

  const handleJourneySearch = (searchText: string) => {
    // setSearchText(searchText)
    // fetchJourneys({search:searchText});
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    //fetchJourneys({search:searchText},page,pageSize)
  };

  return (
    <div className="app-wrapper support">
      <AppHeader
        iconClassName="icon-support"
        title="Support"
        isSearchEnabled={true}
        handleSearch={handleJourneySearch}
        showGoBack={false}
      />

      <TableComponent
        columns={columns}
        data={data}
        loading={false}
        onPageChange={handlePageChange}
        currentPage={1}
        total={1}
      />
    </div>
  );
};

export default Support;
