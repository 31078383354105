import {serializable, alias, primitive, custom} from 'serializr';

export class JourneyTemplateAttachment{
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('id', primitive()))
    key?: number;

    @serializable(alias('attachment', custom(
        (file)=>file,
        (json)=>{}
    )))
    attachment?: File;

    @serializable(alias('attachment_name', primitive()))
    attachmentName?: string;

    @serializable(alias('attachment_url', primitive()))
    attachmentUrl?: string;

    @serializable(alias('description', primitive()))
    description?: string;

    @serializable(alias('template_attachment_type', primitive()))
    templateAttachmentType?: 'carousel' | 'attachment';

    @serializable(alias('step_attachment_type', primitive()))
    stepAttachmentType?: 'carousel' | 'attachment';

    @serializable(alias('template_step_id', primitive()))
    templateStepId?: number;
}
