import { useState } from "react";
import { JourneyTemplateChecklist } from "../../models/JourneyTemplate/journeyTemplateChecklist.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router-dom";

const JourneyTemplateChecklistService = () => {
  const [journeyTemplateChecklist, setJourneyTemplateChecklist] =
    useState<JourneyTemplateChecklist>(new JourneyTemplateChecklist());

  const [journeyTemplateChecklistItems, setJourneyTemplateChecklistItems] =
    useState<Array<JourneyTemplateChecklist>>([]);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [listLoading, setListLoading] = useState<boolean>(false);

  const fetchJourneyTemplateChecklists = (templateStepId?: number) => {
    setListLoading(true);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_CHECKLISTS, {
      id: templateStepId,
    });
    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const journeyTemplateChecklists: any = deserialize(
          JourneyTemplateChecklist,
          response.data
        );
        setJourneyTemplateChecklistItems(journeyTemplateChecklists);
      })
      .catch((error) => {})
      .finally(() => {
        setListLoading(false);
      });
  };

  const createJourneyTemplateChecklist = (
    data: JourneyTemplateChecklist,
    templateStepId?: number
  ) => {
    setButtonLoading(true);
    const serializedData = serialize(JourneyTemplateChecklist, data);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_CHECKLISTS, {
      id: templateStepId,
    });
    return axiosInstance
      .post(apiUrl, serializedData)
      .then((response) => {
        const journeyTemplateChecklist = deserialize(
          JourneyTemplateChecklist,
          response.data
        );
        setJourneyTemplateChecklistItems([
          ...journeyTemplateChecklistItems,
          journeyTemplateChecklist,
        ]);
        setJourneyTemplateChecklist(journeyTemplateChecklist);
        Notification({
          message: "Success",
          description: "Checklist item has been created successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => {})
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const updateJourneyTemplateChecklist = (
    templateStepId?: number,
    checklistItemId?: number,
    data?: JourneyTemplateChecklist
  ) => {
    setButtonLoading(true);
    const serializedData = serialize(JourneyTemplateChecklist, data);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_CHECKLISTS, {
      id: templateStepId,
    });
    return axiosInstance
      .put(apiUrl + `/${checklistItemId}`, serializedData)
      .then((response) => {
        const journeyTemplateChecklist = deserialize(
          JourneyTemplateChecklist,
          response.data
        );
        setJourneyTemplateChecklist(journeyTemplateChecklist);
        Notification({
          message: "Success",
          description: "Checklist item has been updated successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => {})
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const updateJourneyTemplateChecklistOrder = (
    templateStepId?: number,
    checklistItemId?: number,
    data?: JourneyTemplateChecklist
  ) => {
    const serializedData = serialize(JourneyTemplateChecklist, data);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_CHECKLISTS_ORDER, {
      id: templateStepId,
      taskId: checklistItemId,
    });
    return axiosInstance
      .put(apiUrl, serializedData)
      .then((response) => {
        fetchJourneyTemplateChecklists(templateStepId);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const deleteJourneyTemplateChecklist = (
    templateStepId?: number,
    checklistItemid?: number
  ) => {
    setButtonLoading(true);
    let apiUrl = generatePath(ApiRoutes.JOURNEY_TEMPLATE_CHECKLISTS, {
      id: templateStepId,
    });
    return axiosInstance
      .delete(apiUrl + `/${checklistItemid}`)
      .then((response) => {
        Notification({
          message: "Success",
          description: "Checklist item has been deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => {})
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return {
    journeyTemplateChecklist,
    journeyTemplateChecklistItems,
    buttonLoading,
    fetchJourneyTemplateChecklists,
    createJourneyTemplateChecklist,
    deleteJourneyTemplateChecklist,
    updateJourneyTemplateChecklist,
    updateJourneyTemplateChecklistOrder,
    listLoading,
  };
};

export default JourneyTemplateChecklistService;
