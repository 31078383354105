import React, { FC } from "react";
import "./journeyEventDateInfo.scss";
import { Checkbox } from "antd";
import moment from "moment";
import { JourneyTemplateStep } from "../../../../../models/JourneyTemplate/journeyTemplateStep.model";

interface JourneyEventDateInfoProps {
  journeyStep?: JourneyTemplateStep;
}

const JourneyEventDateInfo: FC<JourneyEventDateInfoProps> = (props) => {
  const { journeyStep } = props;
  return (
    <div className="journey-events__date-info">
      {journeyStep?.isCompleted && (
        <Checkbox disabled checked className={"checked"} />
      )}
      <div className="journey-events__date">
        {journeyStep?.stepTime
          ? moment(journeyStep?.stepTime).format("MMM DD")
          : "Date not provided"}
      </div>
      <div className="journey-events__day">
        {journeyStep?.stepTime
          ? moment(journeyStep?.stepTime).format("dddd")
          : ""}
      </div>
    </div>
  );
};

export default JourneyEventDateInfo;
