import { Caretaker } from './../Patient/patient.model';
import { serializable, alias, primitive, object, list } from 'serializr';
import { Patient } from "../Patient/patient.model";
import { Hospital } from "../Hospital/hospital.model";
import { JourneyTemplate } from "../JourneyTemplate/journeyTemplate.model";
import { Staff } from "../Hospital/staff.model";
import { MetaModel } from '../meta.model';

export class Journey {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('id', primitive()))
    key?: number;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('isd_code', primitive()))
    isdCode?: string;

    @serializable(alias('phone_number', primitive()))
    phoneNumber?: string;

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('patient_id', primitive()))
    patientId?: number;

    @serializable(alias('patient', object(Patient)))
    patient?: Patient;

    @serializable(alias('hospital_id', primitive()))
    hospitalId?: string;

    @serializable(alias('guardian', object(Patient)))
    caretaker?: Caretaker;

    @serializable(alias('guardian_id', primitive()))
    caretakerId?: number;

    @serializable(alias('tag_ids', list(primitive())))
    tagIds?: number[];

    @serializable(alias('tags', list(object(MetaModel))))
    tags?: MetaModel[];

    @serializable(alias('hospital', object(Hospital)))
    hospital?: Hospital;

    @serializable(alias('journey_template_id', primitive()))
    journeyTemplateId?: string;

    @serializable(alias('step_time', primitive()))
    stepTime?: string;

    @serializable(alias('journey_template', object(JourneyTemplate)))
    journeyTemplate?: JourneyTemplate;

    @serializable(alias('status', primitive()))
    status?: string;

    @serializable(alias('created_at', primitive()))
    createdAt?: string;

    @serializable(alias('description', primitive()))
    description?: string;

    @serializable(alias('surgery_date', primitive()))
    surgeryDate?: string;

    @serializable(alias('is_published', primitive()))
    isPublished?: boolean;

    @serializable(alias('create_guardian', primitive()))
    createCaregiver?: boolean = false;

    @serializable(alias('employee_ids', list(primitive())))
    staffIds?: Array<string>;

    @serializable(alias('employees', list(object(Staff))))
    staffs?: Array<Staff>;
}

